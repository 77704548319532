import React from 'react';
import './spinner.css';

const LoadingSpinner = () => {
    return (
        <div className="spinner-wrapper">
            <div className="loader">
                <div className="spinner"></div>
                <span className="loader-text">Загрузка...</span>
            </div>
        </div>
    );
};

export default LoadingSpinner;
