import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import companyImage from '../../../images/company/company.png'
import { imageURL } from "../../main/Main";



interface CompanyProps {
    image: string;
    text: string
}

const Company: FunctionComponent<CompanyProps> = (props: CompanyProps) => {
    const { image, text } = props;
    return (
        <section>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left mb-10">
                    О корпорации 1Т
                </h1>
            </ScrollAnimation>
            <div className="min-[1400px]:grid min-[1400px]:grid-cols-2 items-center max-[1400px]:flex max-[1400px]:flex-col min-[1400px]:gap-28 max-[1400px]:gap-8">
                <div className="flex flex-col rounded-2xl items-center max-w-[900px] relative ">
                    <img src={imageURL+image} alt="" />
                </div>
                <div className="flex flex-col font-helio_c text-left">
                    <ScrollAnimation animateIn='fadeIn'>
                        <span className="lg:text-2xl md:text-lg text-left">
                            {text}
                        </span>
                    </ScrollAnimation>
                </div>
            </div>
        </section >
    );
}

export default Company;