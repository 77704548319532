import React, { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IoCloseOutline } from "react-icons/io5";
import InputMask from 'react-input-mask';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { useForm, Controller } from 'react-hook-form';
import { FieldValues } from 'react-hook-form';
import axios from 'axios';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    minWidth: '400px',
    maxWidth: '721px'
};

interface RecordingFormProps {
    open: boolean;
    setOpen: () => void;
    type: string;
}

// const ComponentRecords = () => {

//     const [open, setOpen] = useState(false);


//     const submiteCourse = () => {

//         setOpen(true)

//     }

//     return <div className='flex flex-col justify-center items-center'>
//         <h1 className='min-[1150px]:text-[24px] text-[20px] min-[1400px]:text-[32px] font-bold text-center mb-2'>Форма предварительной записи на онлайн-курс</h1>
//         <span className='text-[1rem] min-[700px]:text-[18px] min-[1150px]:text-[20px] min-[1400px]:text-[24px] text-center mb-4 '>Когда начнется регистрация на курс, наш менеджер свяжется с вами</span>
//         <div className="flex flex-col lg:gap-6 md:gap-4 sm:gap-2 gap-2">
//             <input id='input_fio' type="text" placeholder="ФИО" className="lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] border-white lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]" />
//             <input id='input_telegramm' type="text" placeholder="Телеграм" className="lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] border-white lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]" />
//             <InputMask mask="+7(999)999-99-99">
//                 {/* @ts-ignore */}
//                 {() => { return <input id='input_phone' type="text" placeholder="Телефон" className="lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] border-white lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]" /> }}
//             </InputMask>
//             <input id='input_mail' type="text"  placeholder="Почта" className="lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] border-white lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]" />
//             <FormControlLabel
//                 control={<Checkbox defaultChecked color='warning' id='input_check'/>}
//                 label="Нажимая на кнопку записи вы даёте согласие на обработку своих данных"
//                 sx={{ mb: 2 }}
//             />
//             <button
//                 onClick={(e) => {
//                     submiteCourse()
//                 }}
//                 className="lg:md:text-base sm:text-[12px] text-[12px] text-white bg-orange text-center p-3 font-bold rounded-xl">
//                 Записаться
//             </button>
//             <Snackbar
//                 TransitionComponent={Fade}
//                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//                 open={open}
//                 onClose={() => setOpen(false)}
//                 autoHideDuration={2000}
//             >
//                 <div>
//                     <div className="flex flex-col gap-2 bg-[#FFFFFF] w-full shadow-lg max-w-[375px] justify-center text-black p-5 rounded-2xl">
//                         <span className='text-base font-bold flex flex-row justify-between'>
//                             Спасибо за обращение!

//                             <IconButton
//                                 size="small"
//                                 aria-label="close"
//                                 color="inherit"
//                                 onClick={() => setOpen(false)}
//                             >
//                                 <CloseIcon fontSize="small" />
//                             </IconButton>
//                         </span>
//                         <span className="text-base">
//                             Очень скоро свяжемся с вами. А пока вы можете подробно изучить нас сайт и ознакомиться с нашими программами
//                         </span>
//                     </div>
//                 </div>
//             </Snackbar>
//         </div>
//     </div>
// }



const ComponentRecords = (props: any) => {
    const { setOpen } = props
    type Inputs = {
        fio: string
        telegram: string,
        phone: string,
        email: string,
        consent: boolean
    }


    const [openTooltip, setOpenTooltip] = useState(false);
    const { handleSubmit, control, register, formState: { errors } } = useForm<Inputs>();

    const onSubmit = async (data: FieldValues) => {
        console.log(data);
        await axios.get(`https://admin.aversprofi.ru/rest/DispatchBid/1?emailTo_1=burdin@avers.fm&emailTo_2=podashevko@avers.fm&type=online&phone=${data.phone}&name=${data.fio}&email=${data.email}&tg=${data.telegram}`)
            .then(res => {
                console.log(res);
                setOpen(false);
            })
            .catch(e => {
                console.log(e);
            })
    };

    return (
        <div className='flex flex-col justify-center items-center'>
            <h1 className='min-[1150px]:text-[24px] text-[20px] min-[1400px]:text-[32px] font-bold text-center mb-2'>Форма предварительной записи на онлайн-курс</h1>
            <span className='text-[1rem] min-[700px]:text-[18px] min-[1150px]:text-[20px] min-[1400px]:text-[24px] text-center mb-4'>
                Когда начнется регистрация на курс, наш менеджер свяжется с вами
            </span>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:gap-6 md:gap-4 sm:gap-2 gap-2">
                <Controller
                    name="fio"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'ФИО обязательно' }}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="text"
                            placeholder="ФИО"
                            className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.fio ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                        />
                    )}
                />
                {errors.fio && <span className="text-red-500">{errors.fio.message}</span>}

                <Controller
                    name="telegram"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Телеграм обязательно' }}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="text"
                            placeholder="Телеграм"
                            className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.telegram ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                        />
                    )}
                />
                {/* @ts-ignore */}
                {errors.telegram && <span className="text-red-500">{errors.telegram.message}</span>}

                <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Телефон обязателен' }}
                    render={({ field }) => (
                        <InputMask mask="+7(999)999-99-99" {...field}>
                            {/* @ts-ignore */}
                            {() => {
                                return <input
                                    type="text"
                                    placeholder="Телефон"
                                    className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.phone ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                                />
                            }}
                        </InputMask>
                    )}
                />
                {errors.phone && <span className="text-red-500">{errors.phone.message}</span>}

                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Почта обязательна',
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Некорректный формат почты'
                        }
                    }}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="text"
                            placeholder="Почта"
                            className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.email ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                        />
                    )}
                />
                {errors.email && <span className="text-red-500">{errors.email.message}</span>}

                <Controller
                    name="consent"
                    control={control}
                    defaultValue={false}
                    rules={{ required: 'Необходимо согласие' }}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} color='warning' />}
                            label="Нажимая на кнопку записи вы даёте согласие на обработку своих данных"
                            sx={{ mb: 2 }}
                        />
                    )}
                />
                {errors.consent && <span className="text-red-500">{errors.consent.message}</span>}

                <button
                    type="submit"
                    className="lg:md:text-base sm:text-[12px] text-[12px] text-white bg-orange text-center p-3 font-bold rounded-xl"
                >
                    Записаться
                </button>
            </form>
            <Snackbar
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={openTooltip}
                onClose={() => setOpenTooltip(false)}
                autoHideDuration={2000}
            >
                <div>
                    <div className="flex flex-col gap-2 bg-[#FFFFFF] w-full shadow-lg max-w-[375px] justify-center text-black p-5 rounded-2xl">
                        <span className='text-base font-bold flex flex-row justify-between'>
                            Спасибо за обращение!
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setOpenTooltip(false)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </span>
                        <span className="text-base">
                            Очень скоро свяжемся с вами. А пока вы можете подробно изучить наш сайт и ознакомиться с нашими программами.
                        </span>
                    </div>
                </div>
            </Snackbar>
        </div>
    );
};

const ComponentCallBack = (props: any) => {
    const { setOpen } = props
    type Inputs = {
        fio: string
        phone: string,
        consent: boolean
    }
    const [openTooltip, setOpenTooltip] = useState(false);
    const { handleSubmit, control, register, formState: { errors } } = useForm<Inputs>();



    const onSubmit = async (data: FieldValues) => {
        console.log(data);
        await axios.get(`https://admin.aversprofi.ru/rest/DispatchBid/1?emailTo_1=corp@avers.fm&emailTo_2=filatov@avers.fm&type=number&phone=${data.phone}&name=${data.fio}`)
            .then(res => {
                console.log(res);
                setOpen(false);
            })
            .catch(e => {
                console.log(e);
            })
    };

    return (
        <div className='flex flex-col justify-center items-center'>
            <h1 className='min-[1150px]:text-[24px] text-[20px] min-[1400px]:text-[32px] font-bold text-center mb-2'>Оставьте свой номер</h1>
            <span className='text-[1rem] min-[700px]:text-[18px] min-[1150px]:text-[20px] min-[1400px]:text-[24px] text-center mb-4 '>Оставьте ваши контактные данные, и наши менеджеры свяжутся с вами и подробно проконсультируют по любым интересующим вас вопросам</span>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:gap-6 md:gap-4 sm:gap-2 gap-2">
                <Controller
                    name="fio"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'ФИО обязательно' }}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="text"
                            placeholder="ФИО"
                            className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.fio ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                        />
                    )}
                />
                {errors.fio && <span className="text-red-500">{errors.fio.message}</span>}
                <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Телефон обязателен' }}
                    render={({ field }) => (
                        <InputMask mask="+7(999)999-99-99" {...field}>
                            {/* @ts-ignore */}
                            {() => {
                                return <input
                                    type="text"
                                    placeholder="Телефон"
                                    className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.phone ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                                />
                            }}
                        </InputMask>
                    )}
                />
                {errors.phone && <span className="text-red-500">{errors.phone.message}</span>}
                <Controller
                    name="consent"
                    control={control}
                    defaultValue={false}
                    rules={{ required: 'Необходимо согласие' }}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} color='warning' />}
                            label="Нажимая на кнопку записи вы даёте согласие на обработку своих данных"
                            sx={{ mb: 2 }}
                        />
                    )}
                />
                {errors.consent && <span className="text-red-500">{errors.consent.message}</span>}
                <button type="submit" className="lg:md:text-base sm:text-[12px] text-[12px] text-white bg-orange text-center p-3 font-bold rounded-xl">
                    Оставить заявку
                </button>
                <Snackbar
                    TransitionComponent={Fade}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openTooltip}
                    onClose={() => setOpenTooltip(false)}
                    autoHideDuration={2000}
                >
                    <div>
                        <div className="flex flex-col gap-2 bg-[#FFFFFF] w-full shadow-lg max-w-[375px] justify-center text-black p-5 rounded-2xl">
                            <span className='text-base font-bold flex flex-row justify-between'>
                                Спасибо за обращение!

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => setOpenTooltip(false)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </span>
                            <span className="text-base">
                                Очень скоро свяжемся с вами. А пока вы можете подробно изучить нас сайт и ознакомиться с нашими программами
                            </span>
                        </div>
                    </div>
                </Snackbar>
            </form>
        </div>
    )
}

const RecordingForm = memo((props: RecordingFormProps) => {
    const { open, setOpen, type } = props;



    return (
        <div>
            <Modal
                open={open}
                onClose={() => setOpen()}
            >
                <Box sx={style}>


                    <div className=' bg-[#ECECEC] rounded-xl px-10 min-[700px]:px-[40px] min-[700px]:py-[80px] py-[30px] min-[700px]:py-[40px] min-[1200px]:py-[80px] min-[1200px]:px-[80px] font-helio'>
                        <span className='absolute top-2 right-2 cursor-pointer' onClick={() => setOpen()}>
                            <IoCloseOutline color='orange' className='w-8 h-8' />
                        </span>
                        {type === 'record' ? <ComponentRecords setOpen={setOpen} /> : <ComponentCallBack  setOpen={setOpen} />}
                    </div>
                </Box>
            </Modal>
        </div>
    );
});

export default RecordingForm;

