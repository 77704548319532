import { FunctionComponent } from "react";
import largeLogo from '../../../images/largelogo.png'
import { imageURL } from "../../main/Main";



interface BannerProps {
    title: string,
    desc: string,
    image: string
}

const Banner = ({ data }: { data: BannerProps[] }) => {
    console.log(data);
    
    return (
        <section className="pt-28">
            <div data-color="#FE8016" className="max-[1025px]:mb-10 border-[1px] border-[#C53EAE] rounded-2xl h-full">
                <div className="min-[1320px]:h-[525px] max-[1320px]:h-[775px] lg:md:grid sm:flex lg:md:grid-cols-2 sm:flex-col-reverse flex-col-reverse flex justify-end  grid-flow-dense items-center lg:gap-10 md:gap-8 sm:gap-6 gap-5">
                    <div className="flex flex-col justify-start self-start lg:gap-10 md:gap-8 sm:gap-6 gap-4 lg:p-10 md:p-8 sm:p-6 p-4">
                        <h1 className="text-[#C53EAE] lg:text-[48px] md:text-[40px] sm:text-3xl text-2xl lg:!leading-[60px] font-bold">
                            {data[0].title}
                        </h1>
                        <span className="font-helio_c lg:text-2xl md:text-xl sm:text-base text-sm">
                            {data[0].desc}
                        </span>
                    </div>
                    <div className="flex items-center justify-center p-4">
                        <img src={imageURL + data[0].image} alt="" className="max-[1320px]:pt-5 w-full h-full max-[1320px]:min-h-[360px] max-h-[500px]" />
                    </div>
                    {/* <div className="lg:flex flex-row absolute right-10 bottom-10 gap-4 md:hidden max-[800px]:hidden">
                        <PrevButtton background='[#FE8016]' />
                        <NextButton background='[#FE8016]' />
                    </div> */}
                </div>

            </div>
            {/* <div className="w-full flex justify-center items-center rounded-3xl bg-[#F8F8F8] lg:h-96 md:h-72 sm:h-56 h-56 lg:px-10 md:px-8 sm:px-6 px-6">
                {props.image && <img src={imageURL + props.image} alt="" />}
            </div> */}
        </section>
    );
}

export default Banner;