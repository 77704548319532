import {FunctionComponent} from "react";
import NotFound from '../../images/notFound.svg'
import {useNavigate} from "react-router";

const NotFoundPage: FunctionComponent = () => {
    const navigate = useNavigate()
    return (
        <main className='flex items-center justify-center h-screen'>
            <div className=' px-20 hidden md:flex items-center'>
                <div className='flex flex-col gap-10'>
                    <div>
                        <h2 className='text-[40px] font-bold font-helio'>
                            Ошибка страницы
                        </h2>
                        <p className="font-helio">
                            Такой страницы не существует, но вы можете вернуться на главную и посетить другие страницы нашего сайта
                        </p>
                    </div>
                    <button className="lg:text-lg md:text-base sm:text-[12px]
                min-[800px]:max-w-[300px] text-white font-bold font-helio py-4  px-8 bg-red
                border border-transparent
                hover:bg-transparent hover:border-red hover:text-red
                active:focus:shadow-[inset_0_3px_4px_rgba(0,0,0,0.15)]
                transition-all  active:focus:bg-transparent
                rounded-[2.5rem] flex items-center justify-center"
                            onClick={() => {
                                navigate('/')
                            }}
                    >
                        Главная страница
                    </button>
                </div>

                <div>
                    <img width={1600} src={NotFound} alt={"not found page"}/>
                </div>
            </div>
            <div className='md:hidden px-5 flex flex-col items-center justify-center'>
                <div className='flex flex-col gap-10'>
                    <div>
                        <h2 className='text-[40px] font-bold font-helio'>
                            Ошибка 123123
                        </h2>
                        <p>
                            Такой страницы не существует, но вы можете вернуться на главную и посетить другие страницы нашего сайта
                        </p>
                    </div>
                </div>
                <img src={NotFound} alt={"not found page"}/>
                <button className="lg:text-lg md:text-base sm:text-[12px]
                min-[800px]:max-w-[300px] text-white font-bold font-helio py-4  px-8 bg-red
                border border-transparent
                hover:bg-transparent hover:border-red hover:text-red
                active:focus:shadow-[inset_0_3px_4px_rgba(0,0,0,0.15)]
                transition-all  active:focus:bg-transparent
                rounded-[2.5rem] flex items-center justify-center"
                        onClick={() => {
                            navigate('/')
                        }}
                >
                    Главная страница
                </button>
            </div>
        </main>
    );
}

export default NotFoundPage;