import { FunctionComponent, useState } from "react";
import RecordingForm from "../../modals/RecordingForm";

interface TitlesProps {
    data: {
        title: string,
        description?: string,
        desc?: string,
        dates?: string,
        key?: string,
        status?: string,
        url?: string,
    },
    name?: string
}

const Titles: FunctionComponent<TitlesProps> = ({ data, name }) => {
    const [modalOpen, setModalOpen] = useState(false)

    const handleModal = () =>{
        setModalOpen(prev => !prev)
    }
    return (
        <section>
            <h1 className="lg:text-[48px] md:text-[38px] sm:text-[30px] text-[24px] font-bold font-helio text-left lg:pt-36 md:pt-34 sm:pt-32 pt-32 lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                {name ? `Программа «${name.trim()}»` : data.title}
            </h1>
            <h2 className="lg:text-2xl md:text-xl sm:text-base text-sm font-helio_c">
                {data.desc ?? data.description}
            </h2>
            {data.key &&
                <div className="grid grid-cols-1 min-[650px]:grid-cols-2 gap-2 mt-4 h-full ">
                    <span className="p-4 border-[1px] border-orange order-1 rounded-xl lg:text-2xl md:text-xl sm:text-lg text-[14px] max-[700px]:flex max-[700px]:flex-col" ><b className="text-orange lg:text-2xl md:text-xl sm:text-lg text-[16px]">Статус : </b>{data.status}</span>
                    <span 
                    className="p-4 bg-orange text-white order-3 h-full rounded-xl text-center lg:text-2xl md:text-xl sm:text-lg text-[12px] font-bold cursor-pointer"
                    onClick={handleModal}
                    >Предварительная заявка на курс</span>
                    {data.dates && <div className="p-4 border-[1px] border-orange order-2 row-span-1 min-[650px]:row-span-2 rounded-xl flex flex-col lg:text-2xl md:text-xl sm:text-lg text-[14px] gap-2">
                        <b className="text-orange lg:text-2xl md:text-xl sm:text-lg text-[16px]">Дата курсов : </b>
                        {data.dates}
                    </div>}
                </div>
            }
            <RecordingForm open={modalOpen} setOpen={handleModal} type={"record"}/>
        </section>
    );
}

export default Titles;