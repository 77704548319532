import React, { memo } from 'react';
import { imageURL } from '../../main/Main';
import ScrollAnimation from "react-animate-on-scroll";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface itemAdvantages {
    title: string;
    num: number;
    desc: string;
}

interface whoworkItem {
    MIGX_id: number;
    text: string;
}


interface TargetsComponent {
    data: {
        targets: {
            desc: string;
            image: string
        },
        whowork: {
            title: string,
            values: whoworkItem[]
        },
        advantages: {
            title: string,
            values: itemAdvantages[],
        }
    }
}


const TargetsComponent = memo(({ data }: TargetsComponent) => {
    console.log('main Data:', data);


    return (
        <section>
            <div className='flex flex-row justify-between w-full gap-1 lg:md:flex-nowrap sm:flex-wrap flex-wrap lg:mb-10 md:mb-8 sm:mb-6 mb-6'>
                <div className="flex flex-col gap-6 lg:mb-[60px] md:mb-[40px] sm:mb-6 mb-6 lg:md:w-1/2 w-full">
                    <h2 className="lg:text-[28px] md:text-2xl sm:text-xl text-lg font-bold w-full">
                        Цель —
                    </h2>
                    <span className="font-helio_c lg:text-2xl md:text-xl sm:text-base text-sm ">
                        {data.targets.desc}
                    </span>
                </div>
                <div className='flex  justify-center items-center lg:md:w-1/2 sm:w-full w-full'>
                    <img src={imageURL + data.targets.image} alt='' className='h-full w-full object-cover max-h-[331px] max-w-[491px]' />
                </div>
            </div>
            <div className="flex flex-col lg:gap-10 md:gap-8 sm:gap-6 gap-6 lg:mb-[60px] md:mb-[40px] sm:mb-6 mb-6">
                <h2 className="lg:text-[28px] md:text-2xl sm:text-xl text-lg font-bold">
                    С кем работаем?
                </h2>
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 lg:gap-6 md:gap-4 sm:gap-3 gap-[10px]">
                    {
                        data.whowork.values.map(desc => {
                            return <div className="bg-[#3269A4] text-white py-6 px-3 text-center lg:text-2xl md:text-xl sm:text-base text-[12px] rounded-[20px] flex items-center justify-center">
                                {desc.text}
                            </div>
                        })
                    }

                </div>
            </div>
            <div className="flex flex-col lg:gap-10 md:gap-8 sm:gap-6 gap-6 lg:mb-[40px] md:mb-[30px] sm:mb-6 mb-6">
                <h2 className="lg:text-[28px] md:text-2xl sm:text-xl text-lg font-bold">
                    {data.advantages.title}
                </h2>
            </div>
            <Swiper
                scrollbar={{
                    hide: false,
                    draggable: true,
                }}
                slidesPerView={'auto'}

                spaceBetween={15}
                modules={[Scrollbar]}
                className="items-stretch flex-grow overflow-hidden blue-swiper"
            >
                {
                    data.advantages.values.map((item, index) => {
                        return <SwiperSlide key={index} className="max-w-[370px] pb-10 flex-grow items-stretch min-h-[208px]">
                            <div className="relative max-[1000px]:min-h-[200px] min-[1000px]:min-h-[330px] flex flex-col lg:md:gap-[22px] sm:gap-4 gap-3  items-start max-w-[501px] border-[1px] border-[#3269A4] rounded-2xl lg:p-6 md:p-5 sm:p-4 p-4 font-helio">
                                <span className="lg:text-2xl md:text-xl sm:text-lg text-lg font-bold text-[#3269A4]">
                                    {'0' + (index + 1)}
                                </span>
                               
                                <span className="lg:text-[22px] md:text-xl sm:text-base text-base font-bold">
                                    {item.title}
                                </span>
                                <span className="lg:text-lg md:text-base sm:text-sm text-sm flex-grow font-helio_c">
                                    {item.desc}
                                </span>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    );
});

export default TargetsComponent;
