import { FunctionComponent, useRef } from "react";
import logo from '../../../images/redlogo.png'
import m1 from '../../../images/m1.png'
import m2 from '../../../images/m2.png'
import m3 from '../../../images/m3.png'
import { useNavigate } from "react-router";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Navigation, Pagination } from "swiper/modules";
import PrevButtton from "./PrevButton";
import NextButton from "./NextButton";
import { GeneralSlider } from "../types";
import { imageURL } from "../Main";
interface InfoProps {

}
// document.onload(() => {
//     (document.querySelector('.swiper-pagination-bullet-active') as any).style = `background-color:#FE8016`;

// })


const Info = ({ data }: { data: GeneralSlider[] }) => {
    const navigate = useNavigate()
    return (
        <section className="pt-16">
            <Swiper
                modules={[Navigation, Pagination]}
                // slidesPerView={1}
                spaceBetween={100}

                loop={true}
                onSlideChangeTransitionStart={(swiper: any) => {
                    swiper.slides.map((el: any) => {
                        if (el.classList.contains('swiper-slide-active')) {
                            (document.querySelector('.swiper-pagination-bullet-active') as any).style = `background-color:${el.dataset.color}`;
                            (document.querySelectorAll('.swiper-pagination-bullet'))?.forEach((item: any) => {
                                if (!item.classList.contains('swiper-pagination-bullet-active')) {
                                    item.style = ''
                                }

                            });
                        }
                    })
                }}
                pagination={{ clickable: true }}
                style={{
                    "--swiper-pagination-color": "#FE8016",
                    "--swiper-pagination-bullet-inactive-color": "#999999",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px",
                    "--swiper-pagination-bullet-vertical-margin": "116px",
                } as any}
            >
                {data.map((item, index) => {
                    return <SwiperSlide key={index} data-color={item.colorSlider} className="mb-20 p-1 max-[1025px]:mb-10">
                        <div className="flex flex-col items-center gap-10 mt-16">
                            <div className="grid lg:grid-cols-2 md:sm:grid-cols-1 gap-3">
                                <div className={`flex flex-col justify-between p-6 border-2 rounded-2xl lg:gap-0 max-[1025px]:gap-4 max-[1025px]:p-4`} style={{ borderColor: item.colorSlider }}>
                                    <div className="flex flex-row justify-between">
                                        <div className="lg:max-w-[248px] max-[1025px]:max-w-[150px]">
                                            <img src={logo} alt="" />
                                        </div>
                                        <div className="lg:flex flex-row gap-4 md:hidden max-[800px]:hidden">
                                            <PrevButtton background={item.colorSlider} />
                                            <NextButton background={item.colorSlider} />
                                        </div>
                                    </div>
                                    <div className="lg:hidden md:sm:flex max-w-[500px]">
                                        <img src={imageURL + item.image} alt="" />
                                    </div>
                                    <div className="flex flex-col gap-6 max-[1025px]:gap-3">
                                        <span className="font-bold font-helio lg:text-[40px] md:text-3xl sm:text-2xl text-left">
                                            {item.title}
                                        </span>
                                        <span className="font-[300] font-helio_c lg:text-2xl md:text-xl sm:text-sm text-left">
                                            {item.text}
                                        </span>
                                        <button style={{backgroundColor: item.colorSlider}} className={`lg:text-lg md:text-base sm:text-[12px] min-[800px]:max-w-[300px] text-white font-bold font-helio py-4 px-8 bg-[${item.colorSlider}] hover:opacity-80 active:focus:shadow-[inset_0_3px_4px_rgba(0,0,0,0.15)] transition-all  active:focus:bg-[#FE8016] rounded-[2.5rem] flex items-center justify-center`}
                                         onClick={()=>navigate(item.buttonUrl)}>
                                            Подробнее
                                        </button>
                                    </div>
                                </div>
                                <img src={imageURL + item.image} alt="" className="lg:block max-[1025px]:hidden" />
                            </div>
                            {/* <div className="flex flex-row gap-1">
                            <div className="w-4 h-4 rounded-full bg-[#FE8016]"></div>
                            <div className="w-4 h-4 rounded-full bg-gray"></div>
                            <div className="w-4 h-4 rounded-full bg-gray"></div>
                        </div> */}
                        </div>
                    </SwiperSlide>
                })}

            </Swiper>


        </section >
    );
}

export default Info;