import { FunctionComponent } from "react";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { imageURL } from "../../main/Main";

interface ResultsProps {
    data: {
        title: string,
        withPhotos: {
            text: string,
            title?: string,
            image: string
        }[],
        withOutPhotos?: {
            icon: string,
            text: string
        }[],
        color?: string;
    }
}

const Results: FunctionComponent<ResultsProps> = ({ data }) => {
    
    return (
        <section>
            <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                {data.title}
            </h1>
            <Swiper
                scrollbar={{
                    hide: false,
                    draggable: true,
                }}
                slidesPerView={'auto'}
                autoHeight={true}
                spaceBetween={15}
                modules={[Scrollbar]}
                className="items-stretch orange-swiper flex-grow"
            >
                {
                    data.withPhotos.map((item, index) => {
                        return <SwiperSlide key={index} className="max-w-[370px] pb-10 flex-grow items-stretch">
                            <div className="relative min-[1000px]:min-h-[576px] flex flex-col lg:md:gap-[22px] sm:gap-4 gap-3  items-start max-w-[501px] border-[1px] border-orange rounded-2xl lg:p-6 md:p-5 sm:p-4 p-4 font-helio">
                                <div className="max-h-[380px]">
                                    <img src={imageURL+item.image} alt="" className="max-h-[317px]" />
                                </div>
                                <span className="lg:text-[32px] md:text-xl sm:text-lg text-lg font-bold text-orange">
                                    {'0' + (index + 1)}
                                </span>
                                {item.title && <h3 className='lg:text-xl md:text-base sm:text-sm text-sm flex-grow font-helio_c font-bold'>{item.title}</h3>} 
                                <span className="lg:text-xl md:text-base sm:text-sm text-sm flex-grow font-helio_c">
                                    {item.text}
                                </span>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 lg:gap-6 md:gap-4 sm:gap-3  gap-3 mt-6">
                    {data.withOutPhotos && data.withOutPhotos.map((item, index:number) => {
                        return <div key={index} className="flex flex-col lg:gap-6 md:gap-4 sm:gap-3 gap-3 border-orange border-[1px] lg:py-[30px] md:py-6 sm:py-4 py-4 lg:px-[30px] md:px-6 sm:px-4 px-4 lg:text-2xl md:text-xl sm:text-base text-sm rounded-[20px]">
                            <div className="lg:w-[40px] md:w-[35px] sm:w-[30px] w-[30px] lg:h-[40px] md:h-[35px] sm:h-[30px] h-[30px]">
                                <img src={imageURL+item.icon} alt="" className="w-full h-full" />
                            </div>
                            <span>
                                {item.text}
                            </span>
                        </div>
                    })}
                </div>
        </section>
    );
}

export default Results;