import { FunctionComponent } from "react";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { imageURL } from "../../main/Main";

interface ResultsProps {
    data: {
        title: string,
        withPhotos: {
            desc: string,
            title: string,
            image: string
        }[],
    }
}

const Profits: FunctionComponent<ResultsProps> = ({ data }) => {
    
    return (
        <section>
                <h1 className="lg:text-[28px] md:text-2xl sm:text-xl text-lg font-bold lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                {data.title}
            </h1>
            <Swiper
                scrollbar={{
                    hide: false,
                    draggable: true,
                }}
                slidesPerView={'auto'}
                spaceBetween={15}
                modules={[Scrollbar]}
                className="items-stretch flex-grow blue-swiper"
            >
                {
                    data.withPhotos.map((item, index) => {
                        return <SwiperSlide key={index} className="max-w-[370px] pb-10 flex-grow items-stretch">
                            <div className="relative min-[1000px]:min-h-[640px] max-[1000px]:min-h-[514px] flex flex-col lg:md:gap-[22px] sm:gap-4 gap-3  items-start max-w-[501px] border-[1px] border-[#3269A4] rounded-2xl lg:p-6 md:p-5 sm:p-4 p-4 font-helio">
                                <div className="max-h-[380px]">
                                    <img src={imageURL + item.image} alt="" className="max-h-[317px]" />
                                </div>
                                <span className="lg:text-[32px] md:text-xl sm:text-lg text-lg font-bold text-[#3269A4]">
                                    {'0' + (index + 1)}
                                </span>
                                {item.title && <h3 className='lg:text-[22px] md:text-lg sm:text-base text-base flex-grow font-helio font-bold'>{item.title}</h3>}
                                <span className="lg:text-xl md:text-base sm:text-sm text-sm flex-grow font-helio_c">
                                    {item.desc}
                                </span>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    );
}

export default Profits;