import { useEffect, useLayoutEffect, useState } from "react";
import About from "./about/About";
import Advantages from "./advantages/Advantages";
import Directions from "./directions/Directions";
import Info from "./info/Info";
import { IData } from "./types";
import Works from "./works/Works";
import axios from 'axios'
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";


interface MainProps {

}

export const imageURL = 'https://admin.aversprofi.ru/'

function Main() {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/1')
            .then(res => {
                for (const [key, value] of Object.entries(res.data.object)) {
                    setData(prev => ({ ...prev, [key]: JSON.parse(value as string) }))
                }
                setLoading(false)
            })
        // console.log(data);
        window.scrollTo(0, 0);
    }, [])

    return (
        loading ? <LoadingSpinner /> :
            <main>
                <Info data={(data as any).generalSlider} />
                <Directions data={(data as any).direction} />
                <About data={(data as any).about}/>
                <Works data={(data as any).works}/>
                <Advantages data={(data as any).adventages} />
            </main>
    );
}

export default Main;