import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";

interface itemAdventages{
    MIGX_id : number;
    text : string 
}

interface AdventagesProps {
    data : itemAdventages[]
}

// const data = [
//     'Федеральная программа',
//     'Бесплатное обучение',
//     'Получение сертификата государственного образца по завершению обучения',
//     'Удобный график обучения в онлайн формате',
//     'Оплата учебным учреждениям и преподавателям',
//     'Преподаватели с большим опытом в сфере ИИ, робототехника, программирование',
//     'Сопровождение индивидуальной группы каждого учебного учреждения в вашем часовом поясе'
// ]

const Adventages: FunctionComponent<AdventagesProps> = (props: AdventagesProps) => {
    const {data} = props
    return (
        <section>
            <ScrollAnimation animateIn='fadeInUp'
                >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-12 md:mb-10 sm:mb-6 mb-4">
                    Наши преимущества
                </h1>
            </ScrollAnimation>
            <div className="flex flex-row flex-wrap lg:gap-4 md:gap-3 sm:gap-2 gap-2">
                {
                    data.map(item=> {
                        return <div key={item.MIGX_id} className={`lg:text-2xl md:text-xl sm:text-base text-[12px] whitespace-pre-line border-[1px] font-helio border-orange rounded-xl lg:p-4 md:p-3 sm:p-2 p-2 flex-grow max-w-[501px] w-fit min-[1575px]:[&:nth-child(-n+4)]:w-1/5 [&:nth-child(-n+2)]:w-[40%] [&:nth-last-child(-n+2)]:w-[40%] max-[1575px]:w-full max-[1575px]:max-w-none`}>
                                {item.text}
                        </div>
                    })
                }
            </div>
        </section>
    );
}

export default Adventages;