import { FunctionComponent, useState, memo, Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { MdKeyboardArrowRight } from "react-icons/md";
import { imageURL } from "../../main/Main";

interface StatusProgrammProps {
    data: {
        values: {
            title: string,
            // dop_info_array: {
            //     icon: string,
            //     type : string,
            //     nameame: string,
            //     url: string,
            // }[],
            dop_info_array: string,
        }[],
    }
}

const Additionalinfo: FunctionComponent<StatusProgrammProps> = ({ data }) => {

    const [currentFolder, setCurrentFolder] = useState<number | null>(null)
    return (
        data.values.length > 0 ?
            <section className='flex flex-col lg:gap-13 md:gap-13 sm:gap-8 gap-8 font-helio'>
                <ScrollAnimation animateIn='fadeInUp'
                >
                    <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left">
                        Дополнительная информация
                    </h1>
                </ScrollAnimation>
                <div className="flex flex-col gap-3 overflow-y-auto">
                    {data.values.map((item, index) => {
                        return (
                            <div className={`rounded-2xl transition-all duration-500 bg-[#F8F8F8]`} key={index}>
                                <div className="lg:text-[28px] cursor-pointer md:text-2xl sm:text-xl text-base lg:p-4 md:p-3 p-2  flex flex-row items-center justify-between rounded-2xl" onClick={() => setCurrentFolder(prev => prev == index ? null : index)}>
                                    <span>
                                        {item.title}
                                    </span>
                                    <span className={`${currentFolder == index ? 'rotate-90' : 'rotate-0'} text-center min-w-[20px] w-9 h-9 leading-8 text-3xl text-white  rounded-full btransition-all flex items-center justify-center`}>
                                        <MdKeyboardArrowRight color="orange" />
                                    </span>
                                </div>
                                <div key={index} className={`grid ${currentFolder == index ? 'grid-rows-[1fr] p-2 ' : 'grid-rows-[0fr]'} rounded-2xl overflow-hidden transition-all  lg:text-lg md:text-base sm:text-sm text-[12px] `}>
                                    <div className={`flex flex-col lg:gap-4 md:gap-3 sm:gap-2 gap-2 min-h-0`} key={index}>
                                        {/* @ts-ignore */}
                                        {JSON.parse(item.dop_info_array).map((itemFolder, indexFolder) => {
                                            return (
                                                <Fragment key={indexFolder}>
                                                    {indexFolder === 0 &&
                                                        <div className="w-full flex justify-center items-center">
                                                            <span className="w-[98%] h-[2px] bg-[#A18165] lg:mt-4 md:mt-3 sm:mt-2 mt-1 mb-[10px]" ></span>
                                                        </div>}
                                                    <a className={`flex px-3 flex-row gap-4 items-center`} href={itemFolder.url ? itemFolder.url : imageURL + itemFolder.file}>
                                                        <img src={imageURL + itemFolder.icon} className="h-5 w-5 flex items-center" />
                                                        <span className="flex items-center">{itemFolder.name}</span>
                                                    </a>
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
            : <div></div>
    )
}

export default Additionalinfo