import { Fragment, FunctionComponent, ReactNode } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import whitephone from '../../../images/icons/whitephone.svg'
import whitesmallphone from '../../../images/icons/whitesmallphone.svg'
import arrow from '../../../images/icons/steps/arrow.svg'
import pinkphone from '../../../images/icons/pinkphone.svg'
import questionmark from '../../../images/icons/steps/questionmark.svg'
import radiusarrow from '../../../images/icons/steps/radiusarrow.svg'
import smallarrow from '../../../images/icons/steps/smallarrow.svg'
import marrow from '../../../images/icons/steps/m_arrow.svg'
import barrow from '../../../images/icons/steps/bottomarrow.svg'
import two_arrows from '../../../images/icons/steps/twoarrows.svg'
import { Tooltip } from "flowbite-react";
import nextIcon from '../../../images/icons/steps/m_arrow.svg'
interface StepsProps {

}
const Contacts = () => {
    return <div className="flex flex-col gap-1 items-start min-w-[300px]">
        <span className="flex flex-row items-baseline justify-center gap-2 before:content-[url(./images/icons/whitesmallphone.svg)] before:pt-1">
            Телефон: +7 (391) 277-77-22
        </span>
        <span className="flex flex-row items-baseline justify-center gap-2 before:content-[url(./images/icons/whitetg.svg)] before:pt-1">
            Телеграм: @AversProfi
        </span>
        <span className="flex flex-row items-baseline justify-center gap-2 before:content-[url(./images/icons/whiteemail.svg)] before:pt-1">
            Почта: corp@avers.fm
        </span>
    </div>
}
const Delivery = () => {
    return <div className="min-w-[300px]">
        <span className="w-full">
            Мы самостоятельно привезём и ответственно установим все необходимое оборудование, или же тщательно подготовим его для установки вашими высококвалифицированными специалистами.
        </span>
    </div>
}
const Tech = () => {
    return <div className="min-w-[300px]">
        <span className="w-full">
            Мы регулярно производим техническое обслуживание оборудования, поставляем расходные материалы, а в случае неисправности заменим или отремонтируем оборудование по гарантии в кратчайшие сроки.
        </span>
    </div>
}


const theme = {
    "target": "w-fit",
    "animation": "transition-opacity",
    "arrow": {
        "base": "absolute z-10 h-2 w-2 rotate-45",
        "style": {
            "dark": "bg-gray-900 dark:bg-gray-700",
            "light": "bg-white",
            "auto": "bg-white dark:bg-gray-700"
        },
        "placement": "-4px"
    },
    "base": "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm",
    "hidden": "invisible opacity-0",
    "style": {
        "dark": "bg-[#C53EAE] text-white dark:bg-gray-700",
        "light": "border border-gray-200 bg-white text-gray-900",
        "auto": "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
    },
    "content": "relative z-20"
}

const Steps = (
    { direct_contract, auction, legal_entities, faulties }
        :
        {
            direct_contract: {
                icon: string,
                iconText: string,
                text: string,
                id: string
            }[], auction: {
                icon: string,
                iconText: string,
                text: string,
                id: string
            }[], legal_entities: {
                icon: string,
                iconText: string,
                text: string,
                id: string
            }[], faulties: {
                icon: string,
                iconText: string,
                text: string,
                id: string
            }
        }) => {
            console.log('data:', faulties);
            
    return (
        <>
            <section id={'step3'}>
                <ScrollAnimation animateIn='fadeInUp'
                >
                    <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-32 md:mb-8 sm:mb-6 mb-6">
                        Этапы работы для государственных структур
                    </h1>
                </ScrollAnimation>
                <div className="min-[1300px]:flex max-[1300px]:hidden flex-col font-helio gap-4">
                    <div className="grid grid-cols-20 items-center grid-flow-col">
                        <div className="flex flex-row col-span-2 items-start relative">
                            <div className="-translate-y-[50px]  flex flex-col items-center gap-4">
                                <div className="gap-3 flex relative flex-col items-start bg-pink text-white rounded-2xl p-5">
                                    <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-white font-bold">
                                        1
                                    </span>
                                    <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                                        Связаться с нами
                                    </span>
                                    <div className="absolute -top-3 -right-3">
                                        <Tooltip theme={theme} content={<Contacts />} style="dark" trigger="hover">
                                            <div className={`w-[30px] h-[30px] rounded-full bg-white border-[1px] border-pink flex items-center justify-center `}>
                                                <img src={pinkphone} alt="" className="" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <img src={arrow} alt="" className="" />
                            </div>
                            <img src={radiusarrow} alt="" className="absolute -right-44 -top-4 " />
                        </div>
                        {
                            direct_contract.map((item, index) => {
                                return <Fragment key={index}>
                                    <div className={`flex flex-row w-full items-center  ${index == 0 ? 'col-span-1 ' : 'col-span-1 max-w-[253px]'}`}>
                                        <div className="relative">
                                            {index == 0 && <span className="text-pink absolute -top-9 right-0 whitespace-nowrap text-center font-bold min-[1440px]:text-[28px] md:text-xl sm:text-base text-sm">
                                                Прямой договор
                                            </span>}
                                            <div className={`min-h-[156px] min-w-[206px] max-w-[260px] relative gap-4 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                                <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                                    {item.id}
                                                </span>
                                                <span className="min-[1440px]:text-[28px] md:text-xl sm:text-base text-sm">
                                                    {item.text}
                                                </span>
                                                {item.icon &&
                                                    <div className="absolute -top-3 -right-3">
                                                        <Tooltip theme={theme} content={item.iconText} style="dark" trigger="hover">
                                                            <div>
                                                                <img src={item.icon} alt="" className="" />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div >
                                    <div className="w-full p-3 flex items-center justify-center">
                                        {index != direct_contract.length - 1 &&
                                            <img src={nextIcon} alt="" className=" " />}
                                    </div>
                                </Fragment>
                            })
                        }
                    </div>
                    <div className="grid grid-cols-14 items-center justify-between grid-flow-col">

                        {
                            auction.map((item, index) => {
                                return <Fragment key={index}>
                                    <div className={`flex flex-row w-full items-center  ${index == 0 ? 'col-span-1' : 'col-span-3 max-w-[263px]'}`}>
                                        <div className="relative">
                                            {index == 0 && <span className="text-pink absolute -top-9 right-28 text-center font-bold min-[1440px]:text-[28px] md:text-xl sm:text-base text-sm">
                                                Аукцион
                                            </span>}
                                            <div className={`min-h-[156px] min-w-[206px] relative gap-4 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                                <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                                    {item.id}
                                                </span>
                                                <span className="min-[1440px]:text-[28px] md:text-xl sm:text-base text-sm">
                                                    {item.text}
                                                </span>
                                                {item.icon &&
                                                    <div className="absolute -top-3 -right-3">
                                                        <Tooltip theme={theme} content={item.iconText} style="dark" trigger="hover">
                                                            <div>
                                                                <img src={item.icon} alt="" className="" />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full p-3 flex items-center justify-center">
                                        {index != auction.length - 1 &&
                                            <img src={nextIcon} alt="" className=" " />}
                                    </div>
                                </Fragment>
                            })
                        }
                    </div>
                </div>
                <div className="min-[1300px]:hidden max-[1300px]:flex w-full flex-col items-center lg:gap-8 md:gap-6 sm:gap-4 gap-4 font-helio">
                    <div className="gap-3 flex relative flex-col items-start bg-pink text-white rounded-2xl p-5">
                        <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-white font-bold">
                            1
                        </span>
                        <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                            Связаться с нами
                        </span>
                        <div className="absolute -top-3 -right-3">
                            <Tooltip theme={theme} content={<Contacts />} style="dark" trigger="hover">
                                <div className={`w-[30px] h-[30px] rounded-full bg-white border-[1px] border-pink flex items-center justify-center `}>
                                    <img src={pinkphone} alt="" className="" />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="w-full  flex items-center justify-center">
                        <img src={two_arrows} alt="" className="w-1/2" />
                    </div>
                    <div className="flex flex-row justify-around w-full">
                        <span className="lg:text-3xl md:text-xl sm:text-base text-sm font-bold text-pink">
                            Аукцион
                        </span>
                        <span className="lg:text-3xl md:text-xl sm:text-base text-sm font-bold text-pink">
                            Прямой договор
                        </span>
                    </div>
                    <div className="flex flex-row gap-4 items-start">
                        <div className="flex flex-col gap-2 items-center">
                            {
                                auction.map((item, index) => {
                                    return <Fragment key={index}>
                                        <div className={`flex flex-row w-full flex-grow items-center min-h-[90px]`}>
                                            <div className="relative flex-grow">
                                                <div className={` min-h-[110px] w-full flex-grow relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                                    <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                                        {item.id}
                                                    </span>
                                                    <span className="min-[1440px]:text-[28px] md:text-xl sm:text-base text-sm">
                                                        {item.text}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="max-[1000px]:w-[30px] max-[1000px]:rotate-90">
                                            {index != auction.length - 1 &&
                                                <img src={smallarrow} alt="" className="" />}
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>

                        <div className="flex flex-col justify-start gap-2 items-center">
                            {
                                direct_contract.map((item, index) => {
                                    return <Fragment key={index}>
                                        <div className={`flex flex-row w-full flex-grow min-h-[90px] items-center`}>
                                            <div className="relative flex-grow">
                                                <div className={` w-full min-h-[110px] flex-grow relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                                    <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                                        {item.id}
                                                    </span>
                                                    <span className="min-[1440px]:text-[28px] md:text-xl sm:text-base text-sm">
                                                        {item.text}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="max-[1000px]:w-[30px] max-[1000px]:rotate-90">
                                            {index != direct_contract.length - 1 &&
                                                <img src={smallarrow} alt="" className=" " />}
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>

                    </div>
                </div>

            </section >
            <section>
                <ScrollAnimation animateIn='fadeInUp'
                >
                    <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-20 md:mb-8 sm:mb-6 mb-6">
                        Этапы работы для юридических лиц
                    </h1>
                </ScrollAnimation>
                <div className="max-[1270px]:flex-wrap flex flex-row max-[1000px]:flex-col max-[1000px]:gap-4 items-start max-[1000px]:items-center justify-center font-helio">
                    {legal_entities.map((item: any, index: number) => {
                        return <Fragment key={index}>
                            <div className={`flex flex-row flex-grow justify-center items-center max-w-[300px] min-w-[150px] max-[1000px]:min-h-[50px] min-h-[140px]`}>
                                <div className="relative flex-grow flex items-center justify-center ">
                                    <div className={`flex-grow relative max-[1000px]:gap-2 gap-4 max-[1000px]:min-h-[50px] min-h-[140px] max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                        <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                            {item.id}
                                        </span>
                                        <span className="min-[1440px]:text-[28px] md:text-xl sm:text-base text-sm">
                                            {item.text}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {index != legal_entities.length - 1 && <div className="max-w-[100px] max-[1000px]:p-1 p-3 flex items-center justify-center self-center">
                                <img src={nextIcon} alt="" className="max-[1000px]:w-[30px] max-[1000px]:rotate-90" />
                            </div>}
                        </Fragment>
                    })}

                </div>
            </section>
            <section>
                <ScrollAnimation animateIn='fadeInUp'
                >
                    <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-20 md:mb-8 sm:mb-6 mb-6">
                        Если товар неисправен
                    </h1>
                </ScrollAnimation>
                <div className="min-[1300px]:flex max-[1300px]:hidden flex-row max-w-[800px] font-helio gap-4">
                    <div className={`col-start-1 col-end-2 min-h-[156px] min-w-[283px] relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                        <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                            1
                        </span>
                        <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                            Связаться с нами
                        </span>
                        <div className="absolute -top-3 -right-3">
                            <Tooltip theme={theme} content={<Contacts />} style="dark" trigger="hover">
                                <div className={`w-[30px] h-[30px] rounded-full bg-white border-[1px] border-pink flex items-center justify-center `}>
                                    <img src={whitephone} alt="" className="" />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="col-start-3 col-end-4 w-full p-3 flex items-center justify-center">
                        <img src={marrow} alt="" className=" " />
                    </div>
                    <div className={`col-start-4 col-end-5 min-h-[156px] min-w-[343px] relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl `}>
                        <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                            2
                        </span>
                        <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                            Тех.обслуживание
                        </span>
                        <div className="absolute -top-3 -right-3">
                            <Tooltip theme={theme} content={<Tech />} style="dark" trigger="hover">
                                <img src={questionmark} alt="" className="" />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="min-[1300px]:flex max-[1300px]:hidden flex-row max-w-[850px] ml-[460px] gap-4 font-helio mt-10">
                    <div className={`col-start-4 col-end-5 min-h-[156px] min-w-[343px] relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                        <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                            2
                        </span>
                        <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                            Гарантия
                        </span>
                        <img src={barrow} alt="" className="absolute -left-[300px] -top-[25px]" />
                    </div>
                    <div className="col-start-6 col-end-8 w-full p-3 flex items-center justify-center">
                        <img src={marrow} alt="" className=" " />
                    </div>
                    <div className={`col-start-8 col-end-9 min-h-[156px] min-w-[343px] relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                        <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                            3
                        </span>
                        <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                            Замены
                        </span>
                    </div>
                </div>
                <div className="min-[1300px]:hidden max-[1300px]:flex w-full flex-col items-center lg:gap-8 md:gap-6 sm:gap-4 gap-4 font-helio">
                    <div className={` relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                        <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                            1
                        </span>
                        <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                            Связаться с нами
                        </span>
                        <div className="absolute -top-3 -right-3">
                            <Tooltip theme={theme} content={<Contacts />} style="dark" trigger="hover">
                                <div className={`w-[30px] h-[30px] rounded-full bg-white border-[1px] border-pink flex items-center justify-center `}>
                                    <img src={pinkphone} alt="" className="" />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="w-full  flex items-center justify-center">
                        <img src={two_arrows} alt="" className="w-1/2" />
                    </div>
                    <div className="flex flex-row w-full justify-between gap-4">
                        <div className="flex flex-col w-full">
                            <div className={`relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                    2
                                </span>
                                <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                                    Тех.обслуживание
                                </span>
                                <div className="absolute -top-3 -right-3">
                                    <Tooltip theme={theme} content={<Tech />} style="dark" trigger="hover">
                                        <img src={questionmark} alt="" className="" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <div className={`relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                    2
                                </span>
                                <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                                    Гарантия
                                </span>
                                <img src={barrow} alt="" className="max-[1270px]:hidden absolute -left-[300px] -top-[25px]" />
                            </div>
                            <div className="w-full p-3 flex items-center justify-center">
                                <img src={marrow} alt="" className="max-[1270px]:rotate-90 max-[1270px]:w-[40px]" />
                            </div>
                            <div className={`relative max-[1000px]:gap-2 gap-4 max-[1000px]:p-2 p-4 flex flex-col items-start border-[1px] border-pink text-black rounded-2xl`}>
                                <span className="lg:text-[32px] md:text-2xl sm:text-lg text-base text-pink font-bold">
                                    3
                                </span>
                                <span className="lg:text-[28px] md:text-xl sm:text-base text-sm">
                                    Замены
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

export default Steps;