// componenets
import Titles from "./titles/Titles";
import Programs from "./programs/Programs";
import Trajectories from "./trajectories/Trajectories";
import Advantages from "./adventages/Adventages";
import Results from "./results/Results";
import Roadmap from "./roadmap/Roadmap";
import Questions from "./questions/Questions";
import Feedback from "./feedback/Feedback";
import Additionalinfo from "./additionalinfo/Additionalinfo";

// photos
import bpla from './../../images/programs/bpla.png'
import gaming from './../../images/programs/gaming.png'
import star from './../../images/icons/star.svg'
import done from './../../images/icons/done.svg'
import education from './../../images/icons/education.svg'
import business from './../../images/icons/business.svg'
import teenagers from './../../images/programs/adventages/teenagers.png'
import tasks from './../../images/programs/adventages/tasks.png'
import educ from './../../images/programs/adventages/education.png'
import cert from './../../images/icons/cert.svg'
import up from './../../images/programs/results/up.png'
import part from './../../images/programs/results/part.png'
import teacher from './../../images/programs/results/teacher.png'
import success from './../../images/programs/results/success.png'

export { bpla, gaming, star, done, education, business, teenagers, tasks, educ, cert, up, part, teacher, success }
export { Titles, Programs, Trajectories, Advantages, Results, Roadmap, Questions, Feedback, Additionalinfo }