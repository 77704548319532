import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import kod from '../../../images/icons/programs/kod.svg'
import demo from '../../../images/icons/programs/demo.svg'
import bas from '../../../images/icons/programs/bas.svg'
import ii from '../../../images/icons/programs/ii.svg'
import dop from '../../../images/icons/programs/dop.svg'
import simplearrow from '../../../images/icons/simplearrow.svg'
import { imageURL } from "../../main/Main";

interface ItemProgram {
    title: string;
    desc: string;
    icon: string;
    url: string;
}

interface ProgramsProps {
    data: ItemProgram[];
}

// const data = [
//     {
//         title: 'Код будущего',
//         desc: 'Бесплатные курсы по программированию, предлагаемые для 8-11 классов, призваны помочь освоить основные навыки.',
//         icon: kod,
//         url: '/'
//     },
//     {
//         title: 'Демография',
//         desc: 'Программы повышения квалификации для педагогов включают курсы для обновления знаний.',
//         icon: demo,
//         url: '/'
//     },
//     {
//         title: 'БАС',
//         desc: 'Развитие беспилотных авиационных систем, подготовка высококвалифицированных кадров.',
//         icon: bas,
//         url: '/'
//     },
//     {
//         title: 'Искусственный интеллект',
//         desc: 'Обучающие программы в рамках федерального проекта «Искусственный интеллект».',
//         icon: ii,
//         url: '/'
//     },
//     {
//         title: 'Дополнительное платное образование',
//         desc: 'Банальные, но неопровержимые выводы, а также независимые государства будут разоблачены.',
//         icon: dop,
//         url: '/'
//     },
// ]

const Programs: FunctionComponent<ProgramsProps> = (props: ProgramsProps) => {
    const { data } = props
    return (
        <section className='flex flex-col font-helio'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                    Программы
                </h1>
            </ScrollAnimation>

            <div className="flex flex-row gap-4 flex-wrap">
                {
                    data.map((item, index) => {
                        if (item.url) {
                            return <a href={item.url} key={index} className="relative flex flex-col grow lg:md:gap-3 sm:gap-[10px] gap-[10px] items-start max-w-[501px] border-[1px] border-orange rounded-2xl lg:p-6 md:p-5 sm:p-4 p-4">
                                <img src={imageURL + item.icon} alt="" />
                                <span className="lg:text-2xl md:text-xl sm:text-base text-sm font-bold">
                                    {item.title}
                                </span>
                                <span className="lg:text-2xl md:text-xl sm:text-base text-sm flex-grow whitespace-normal ">
                                    {item.desc}
                                </span>
                                {item.url &&
                                    <span className="text-orange justify-self-auto flex flex-row items-center lg:text-lg md:text-base sm:text-sm text-[12px] underline">
                                        Перейти
                                        <img src={simplearrow} alt="" className="max-[1000px]:w-[12px] max-[1000px]:h-[12px] max-[1000px]:ml-1" />
                                    </span>}
                            </a>
                        }
                        else {
                            return <div key={index} className="relative flex grow flex-col lg:md:gap-3 sm:gap-[10px] gap-[10px] items-start max-w-[501px] border-[1px] border-orange rounded-2xl lg:p-6 md:p-5 sm:p-4 p-4">
                                <img src={imageURL + item.icon} alt="" />
                                <span className="lg:text-2xl md:text-xl sm:text-base text-sm font-bold">
                                    {item.title}
                                </span>
                                <span className="lg:text-2xl md:text-xl sm:text-base text-sm flex-grow whitespace-normal ">
                                    {item.desc}
                                </span>
                                {item.url &&
                                    <a href={item.url} className="text-orange justify-self-auto flex flex-row items-center lg:text-lg md:text-base sm:text-sm text-[12px] underline">
                                        Перейти
                                        <img src={simplearrow} alt="" className="max-[1000px]:w-[12px] max-[1000px]:h-[12px] max-[1000px]:ml-1" />
                                    </a>}
                            </div>

                        }
                    })
                }
            </div>
        </section>
    );
}

export default Programs;