import { FunctionComponent, useRef } from "react";
import logo from '../../../images/redlogo.png'
import m1 from '../../../images/m1.png'
import m2 from '../../../images/m2.png'
import m3 from '../../../images/m3.png'

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Navigation, Pagination } from "swiper/modules";
import PrevButtton from "./PrevButton";
import NextButton from "./NextButton";
import robot from '../../../images/1t/robot.svg'
import besp from '../../../images/1t/besp.svg'
import ii from '../../../images/1t/ii.svg'
import { imageURL } from "../../main/Main";


interface itemInfo {
    title: string;
    desc: string;
    image: string;
}

interface InfoProps {
    data : itemInfo[];
}
// const data = [
//     {
//         title: 'Код будущего',
//         desc: 'Бесплатные онлайн-курсы для школьников 8–11 классов и студентов СПО. Цель 1Т Start — пробудить в обучающихся учебный азарт, помогающий получать знания в процессе работы над своими собственными проектами. Мы учим ребят работать со скриптами JavaScript и Python, создавать игровые ситуации на основе различных сценариев в различных средах, помогаем «оживить» их и максимально приблизить к реальности',
//         img: robot
//     },
//     {
//         title: 'Искусственный интеллект',
//         desc: 'Бесплатные онлайн-курсы для школьников 8–11 классов и студентов СПО. Цель 1Т Start — пробудить в обучающихся учебный азарт, помогающий получать знания в процессе работы над своими собственными проектами.',
//         img: ii
//     },
//     {
//         title: 'Беспилотники',
//         desc: 'Бесплатные онлайн-курсы для школьников 8–11 классов и студентов СПО. Цель 1Т Start — пробудить в обучающихся учебный азарт, помогающий получать знания в процессе работы над своими собственными проектами. Мы учим ребят работать со скриптами JavaScript и Python, создавать игровые ситуации на основе различных сценариев в различных средах, помогаем «оживить» их и максимально приблизить к реальности',
//         img: besp
//     }
// ]


const Info: FunctionComponent<InfoProps> = (props : InfoProps) => { 
    const {data} = props;
    
    return (
        <section className="pt-28 font-helio">
            <Swiper
                modules={[Navigation, Pagination]}
                // slidesPerView={1}
                spaceBetween={100}

                loop={true}
                onSlideChangeTransitionStart={(swiper: any) => {
                    swiper.slides.map((el: any) => {
                        if (el.classList.contains('swiper-slide-active')) {
                            (document.querySelector('.swiper-pagination-bullet-active') as any).style = `background-color:${el.dataset.color}`;
                            (document.querySelectorAll('.swiper-pagination-bullet'))?.forEach((item: any) => {
                                if (!item.classList.contains('swiper-pagination-bullet-active')) {
                                    item.style = ''
                                }

                            });
                        }
                    })
                }}
                pagination={{ clickable: true }}
                style={{
                    "--swiper-pagination-color": "#FE8016",
                    "--swiper-pagination-bullet-inactive-color": "#999999",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px",
                    "--swiper-pagination-bullet-vertical-margin": "116px",
                } as any}
            >
                {data.map((item, index) => {

                    return <SwiperSlide key={index} data-color="#FE8016" className="max-[1025px]:mb-10 border-[1px] border-orange rounded-2xl h-full">
                        <div className="min-[1320px]:h-[525px] max-[1320px]:h-[775px] lg:md:grid sm:flex lg:md:grid-cols-2 sm:flex-col-reverse flex-col-reverse flex justify-end  grid-flow-dense items-center lg:gap-10 md:gap-8 sm:gap-6 gap-5">
                            <div className="flex flex-col justify-start self-start lg:gap-10 md:gap-8 sm:gap-6 gap-4 lg:p-10 md:p-8 sm:p-6 p-4">
                                <h1 className="text-orange lg:text-[48px] md:text-[40px] sm:text-3xl text-2xl lg:!leading-[60px] font-bold">
                                    {item.title}
                                </h1>
                                <span className="font-helio_c lg:text-2xl md:text-xl sm:text-base text-sm">
                                    {item.desc}
                                </span>
                            </div>
                            <div className="flex items-center justify-center p-4">
                                <img src={imageURL+item.image} alt=""  className="max-[1320px]:pt-5 w-full h-full max-[1320px]:min-h-[360px] max-h-[500px]"/>
                            </div>
                            <div className="lg:flex flex-row absolute right-10 bottom-10 gap-4 md:hidden max-[800px]:hidden">
                                <PrevButtton background='[#FE8016]' />
                                <NextButton background='[#FE8016]' />
                            </div>
                        </div>

                    </SwiperSlide>
                })}

            </Swiper>


        </section >
    );
}

export default Info;