import { FunctionComponent, useEffect, useState } from "react";
import * as imports from "./imports";
import { useParams } from "react-router";
import axios from "axios";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import NotFoundPage from "../utils/NotFoundPage";


interface ProgramTemplateProps {

}

// const data = {
//     'kod-budushego': {
//         titles: {
//             title: 'Программа "Код будущего"',
//             description: 'Бесплатные онлайн-курсы для школьников 8-11 классов и студентов СПО. Мы учим ребят работать со скриптами JavaScript и Python, создавать игровые ситуации на основе различных сценариев в различных средах, помогаем «оживить» их и максимально приблизить к реальности.'
//         },
//         programs: {
//             title: "Программы",
//             slides: [
//                 {
//                     title: "Робототехника и БПЛА в смешанной реальности",
//                     description: 'На курсе вы освоите начальные знания и терминологию профессионального языка в области робототехники и БПЛА, познакомитесь с актуальными программными инструментами. Во время курса вы сможете создать собственную упрощённую модель дрона, запрограммировать его простейшие манипуляции.',
//                     modules: {
//                         title: 'Модули',
//                         values: ["Основы робототехники", 'Разработка дронов', 'Моделирование дронов', 'Отраслевые решения и ИИ'],
//                         icon: imports.star
//                     },
//                     image: imports.bpla,
//                     url: '/'
//                 },
//                 {
//                     title: "Моделирование игрвых миров на JAVA script",
//                     description: 'Курс включает изучение вопросов по разработке современных компьютерных игр, основных программных инструментов и характеристик кроссплатформенного API WebGL. Программа нацелена на получение практических навыков по моделированию игровых миров и решению основных задач, требующихся при разработке игр на Javascript, по работе с программными инструментами необходимыми для этих целей. В результате по желанию вы сможете создать собственную компьютерную игру на Javascript.',
//                     modules: {
//                         title: 'Модули',
//                         values: ["Введение в разработку игр", 'Основы програмирования JAVA script', 'Програмирование игровой логики', 'Работа с данными и работа с ИИ'],
//                         icon: imports.star
//                     },
//                     image: imports.gaming,
//                     url: '/'
//                 },
//                 {
//                     title: "Искуственный интеллект с PYTHON",
//                     description: 'На курсе вы получите свои первые навыки программирования на Python — самом популярном языке программирования в мире. Это эффективный и универсальный язык с простым синтаксисом, и он отлично подходит для обучения. Во время курса вы научитесь обучать и оптимизировать нейронные сети и создадите свою собственную. Попробуйте себя в профессии будущего!',
//                     modules: {
//                         title: 'Модули',
//                         values: ["Знакомство с PYTHON и ИИ", 'ООП на PYTHON', 'Создание графических приложений ', 'Создание и обучение ИИ'],
//                         icon: imports.star
//                     },
//                     image: imports.bpla,
//                     url: '/'
//                 }

//             ],
//         },
//         trajectories: {
//             title: 'Гибкие образовательные траектории',
//             target: 'пробудить в обучающемся учебный азарт, помогающий получать знания в процессе работы над своими собственными проектами',
//             whocan: {
//                 title: "Кто может обучаться?",
//                 values: [
//                     'Кто интересуется программированием и IT разработкой',
//                     'Хочет стать IT-профи, востребованным во многих отраслях',
//                     'Имеет склонности в области математики и ифнорматики',
//                     'Внимателен, усидчив и не ищет готовых решенийа'
//                 ]
//             },
//             approaches: {
//                 title: 'Ключевые подходы',
//                 values: [{
//                     text: 'Персональное сопровождение и поддержка обучаемых',
//                     icon: imports.done
//                 },
//                 {
//                     text: 'Только эксперты с опытом в робототехнике и программировании БПЛА.',
//                     icon: imports.education
//                 },
//                 {
//                     text: 'Использование современного программного обеспечения',
//                     icon: imports.business
//                 }
//                 ]
//             }
//         },
//         advantages: {
//             title: "Преимущества",
//             values: [{
//                 title: "Для разных категорий подростков",
//                 desc: 'Для школьников 8–10 классов, выпускников 11 класса и студентов среднего профессионального образования',
//                 image: imports.teenagers
//             },
//             {
//                 title: "Быстрые и качественные знания ",
//                 desc: 'Уже пройдя 4 модуля обучения вы получите знания о коде и достигните начального уровеня программирования ',
//                 image: imports.tasks
//             },
//             {
//                 title: "Удобное и бесплатное обучение",
//                 desc: 'Удобное и бесплатное обучение в онлайн формате, которое дает возможность обучаться с любой точки мира',
//                 image: imports.educ
//             },
//             {
//                 title: "Удобное и бесплатное обучение",
//                 desc: 'Удобное и бесплатное обучение в онлайн формате, которое дает возможность обучаться с любой точки мира',
//                 image: imports.educ
//             },

//             ]
//         },
//         results: {
//             title: "Что получите?",
//             color: 'orange',

//             withPhotos: [{
//                 desc: 'Повышение мотивации молодёжи к обучению в IT сфере',
//                 image: imports.up
//             },
//             {
//                 desc: 'Совершенствование компетенций педагогов в сфере IT',
//                 image: imports.teacher
//             },
//             {
//                 desc: 'Статус участника федерального проекта и дополнительный доход',
//                 image: imports.part
//             },
//             {
//                 desc: 'Развитие систем дополнительного образования',
//                 image: imports.success
//             },
//             ],
//             withOutPhotos: [{
//                 icon: imports.education,
//                 text: '270 часов, из них более 50% блоков с практической подготовкой',
//             },
//             {
//                 icon: imports.cert,
//                 text: 'По окончании сертификат о прохождении курса',
//             },
//             {
//                 icon: imports.business,
//                 text: 'Перспективная и востребованная профессия',
//             }]
//         },
//         roadmap: {
//             title: 'Ваша дорожная карта',
//             values: [{
//                 title: 'Взять справку в школе/колледже',
//                 // desc: 'Справка действительна в течении 30 дней'
//                 desc: 'Получение справки в школе/СПО',
//                 tooltip: 'Справка действительна в течение 30 дней'
//             },
//             {
//                 title: 'Подать заявку',
//                 // desc: 'Через сайт Госуслуг'
//                 desc: 'Регистрация на платформе Leader-ID',
//                 tooltip: 'Через сайт Госуслуг'
//             },
//             {
//                 title: 'Получить одобрение',
//                 desc: 'Подача заявки на курс через платформу Госуслуги'
//             },
//             {
//                 title: 'Заявки на Госуслугах',
//                 desc: 'Получение одобрения заявки на Госуслугах'
//             },
//             {
//                 title: 'Пройти вступительное испытание',
//                 desc: 'Прохождение вступительного испытания на платформе 1Т Старт в течении 10 рабочих дней'
//             },
//             {
//                 title: 'Пройти вступительное испытание',
//                 desc: 'Ознакомление с результатами тестирования и ожидание начала обучения'
//             },

//             ]
//         },
//         qustions: {
//             title: 'Частые вопросы',
//             values: [{
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             }]
//         },
//         feeback: {
//             title: 'Не получилось найти ответ на свой вопрос?',
//             desc: 'Оставтье свои данные. Мы свяжется с вами, ответим на все вопросы подробно и расскажем обо всех преимуществах программы'
//         }
//     },
//     'bas': {
//         titles: {
//             title: 'Программа «БАС»',
//             description: 'Программа «Беспилотные Авиационные Системы» — это гибкая образовательная траектория массового обучения. Подготовка к новым видам деятельности, не только пилотирования. Одна из перспективных отраслей, где особенно нужен ИИ и российская микроэлектроника.'
//         },
//         programs: {
//             title: "Программы",
//             slides: [
//                 {
//                     title: "Проектирование БАС",
//                     description: 'Курс профессиональной переподготовки ориентирован на совершенствование и получение новых профессиональных компетенций в области проектирования конструкций (узлов, механизмов, компоновки) и функционального оборудования БАС,. Слушатели получат знания оьб архитектуре и устройстве БАС, их типовых конструкциях, а также освоят практические навыки конструирования БАС и их комплектующих',
//                     modules: {
//                         title: 'Модули',
//                         values: [
//                             "Введение в БАС: Архитектура, организация мастерской симуляторы, особенности сборки.",
//                             'Практическая часть: Полет на БАС, конструирование комплектующих, сборка БАС разных типов.'
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.bpla,
//                     url: '/'
//                 },
//                 {
//                     title: "Оператор БВС: пилотирование и эксплуатация",
//                     description: 'Направлен на получение новых компетенций, необходимых для профессиональной деятельности в области управления беспилотными авиационными системами. Слушатели получат знания о принципах работы и устройстве БАС, различных типах беспилотных воздушных судов и их применении, а также об основах ремонта и эксплуатации.',
//                     modules: {
//                         title: 'Модули',
//                         values: ["Архитектура, симуляторы, управление и ремонт, алгоритмы автономного пилотирования.",
//                             'Практическая часть. Основы пилотирования',
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.gaming,
//                     url: '/'
//                 },
//             ],

//         },
//         trajectories: {
//             title: 'Гибкие образовательные траектории',
//             target: 'Обеспечивание профессионального развития граждан и подготовка кадров для разработки, производства и эксплуатации БАС.',
//             whocan: {
//                 title: "Кто может обучаться?",
//                 values: [
//                     'Граждане РФ — жители всех регионов России',
//                     'Граждане, не достигшие пенсионного возраста',
//                     'Граждане, имеющие СПО или высшее образование',
//                     'Обучающиеся последних курсов СПО (16+), ВУЗов'
//                 ]
//             },
//             approaches: {
//                 title: 'Ключевые подходы',
//                 values: [{
//                     text: 'Оценка компетенции граждан экспертным сообществом',
//                     icon: imports.done
//                 },
//                 {
//                     text: 'Гибкие образовательные траектории обучения',
//                     icon: imports.education
//                 },
//                 {
//                     text: 'Практико-ориентированное обучение',
//                     icon: imports.business
//                 },
//                 {
//                     text: 'Актуальная теория в LMS и живые вебинары.',
//                     icon: imports.business
//                 },
//                 {
//                     text: 'Симуляторы полетов на различных моделях БАС в виртуальной мастерской.',
//                     icon: imports.business
//                 }
//                 ]
//             }
//         },
//         advantages: {
//             title: "Преимущества",
//             values: [{
//                 title: "Гибкий график обучения",
//                 desc: 'Гибкое сочетание онлайн и офлайн форматов позволяет учиться параллельно с работой или основным образованием.',
//                 image: imports.teenagers
//             },
//             {
//                 title: "Современное ПО",
//                 desc: 'Которое помогут освоить актуальные инструменты и технологии в сфере БАС.',
//                 image: imports.tasks
//             },
//             {
//                 title: "Персональное сопровождение",
//                 desc: 'Мы поддерживаем каждого слушателя, помогаем успешно усваивать материал и решать возникающие вопросы.',
//                 image: imports.educ
//             },
//             {
//                 title: "База знаний",
//                 desc: 'Комплексный подход к программе позволяет получить системные знания в сфере БАС.',
//                 image: imports.educ
//             },
//             {
//                 title: "Удобное и бесплатное обучение",
//                 desc: 'Удобное и бесплатное обучение в онлайн формате, которое дает возможность обучаться с любой точки мира',
//                 image: imports.educ
//             },

//             ]
//         },
//         results: {
//             title: "Что получите?",
//             color: 'orange',

//             withPhotos: [{
//                 desc: 'Сможете собирать и модернизировать БАС с использованием микроконтроллеров и одноплатных ПК.',
//                 image: imports.up
//             },
//             {
//                 desc: 'Использовать симуляторы для разной оценки корректности сборки БАС.',
//                 image: imports.teacher
//             },
//             {
//                 desc: 'Самостоятельно моделировать в программах и печатать 3D-детали БАС.',
//                 image: imports.part
//             },
//             {
//                 desc: 'Программировать в среде С++, MIK32 АМУР и аналогичных средах.',
//                 image: imports.success
//             },
//             {
//                 desc: 'Проектировать конструкцию (узлы, механизмы, компоновку) и функциональное оборудование БАС.',
//                 image: imports.success
//             },
//             {
//                 desc: 'Конструировать комплектующие и собирать беспилотные авиационные системы различных типов.',
//                 image: imports.success
//             },
//             ],
//             withOutPhotos: [{
//                 icon: imports.education,
//                 text: 'от 144 часа до 270, из них более 50% – блок практической подготовки',
//             },
//             {
//                 icon: imports.cert,
//                 text: 'По окончании диплом о профессиональной переподготовке',
//             },
//             {
//                 icon: imports.business,
//                 text: 'Перспективная и востребованная профессия',
//             }]
//         },
//         roadmap: {
//             title: 'Ваша дорожная карта',
//             values: [{
//                 desc: 'Заполнение предварительной заявки на обучение;'
//             },
//             {
//                 desc: 'Регистрация на Leader-ID;'
//             },
//             {
//                 desc: 'Подготовка документов для регистрации',
//                 tooltip: `- паспорт, 
// - СНИЛС,
// - Документ об образовании,
// - Справка с места обучения (для студентов);`
//             },
//             {
//                 desc: 'Ожидание одобрения заявки на обучение.'
//             },
//             {
//                 desc: 'Заполнение анкеты, загрузка документов и отправка на согласование;'
//             },
//             {
//                 desc: 'Получение ссылки на официальную регистрацию'
//             },
//             ]
//         },
//         qustions: {
//             title: 'Частые вопросы',
//             values: [{
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             }]
//         },
//         feeback: {
//             title: 'Не получилось найти ответ на свой вопрос?',
//             desc: 'Оставтье свои данные. Мы свяжется с вами, ответим на все вопросы подробно и расскажем обо всех преимуществах программы'
//         }
//     },
//     'ii': {
//         titles: {
//             title: 'Программа «Искусственный интеллект»',
//             description: 'Курсы нацелены на получение компетенций, необходимых для профессиональной деятельности аналитика данных для разработки и применения технологических решений в области искусственного интеллекта и в смежных областях.'
//         },
//         programs: {
//             title: "Программы",
//             slides: [
//                 {
//                     title: "Архитектор в области ИИ (DS)",
//                     description: 'Специалист, который для решения прикладных задач проектирует решения на базе искусственного интеллекта и осуществляет обучение нейронных сетей и моделей машинного обучения. Изучение функций, задач, навыков, содержания работы Архитектора в области искусственного интеллекта, методов и программного инструментария технологий больших данных, синтаксис и семантику основных языков искусственного интеллекта (Python, JAVA) и основные приёмы программирования на них.',
//                     modules: {
//                         title: 'Модули',
//                         values: [
//                             "Введение в инженерию ",
//                             'Основы хранения и обработки данных',
//                             'Архитектура ML',
//                             'Архитектура ИИ и нейронных сетей'
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.bpla,
//                     url: '/'
//                 },
//                 {
//                     title: "Архитектор данных (Devops)",
//                     description: 'Специалист, который отвечает за проектирование и создание инфраструктуры данных в организации, а также занимается разработкой и оптимизацией баз данных, созданием алгоритмов обработки данных и обеспечением их безопасности',
//                     modules: {
//                         title: 'Модули',
//                         values: [
//                             "Введение в системное администрирование больших данных",
//                             'Введение в архитектуру больших данных',
//                             'Проектирование архитектур: большие данные, машинное обучение, ии',
//                             'Построение архитектур больших данных '
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.gaming,
//                     url: '/'
//                 },
//                 {
//                     title: "Аналитик данных",
//                     description: 'Специалист, который занимается извлечением, анализом и интерпретацией данных с целью выявления закономерностей, трендов и паттернов, используя различные методы статистики, машинного обучения и анализа данных',
//                     modules: {
//                         title: 'Модули',
//                         values: [
//                             "Введение в анализ данных",
//                             'Инструменты, библиотеки и технологии анализа данных',
//                             'Продвижение продукта. Бизнес метрики ',
//                             'Глубокое обучение и нейронные сети',
//                             'Технологии хранения и обработка больших данных',
//                             'Математическое моделирование. машинное обучение и оптимизация'
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.gaming,
//                     url: '/'
//                 },
//                 {
//                     title: "Руководитель проектов в области ИИ (PM)",
//                     description: 'Специалист, который планирует, координирует и контролирует выполнение проектов разработки внедрения инновационных решений, основанных на использовании искусственного интеллекта. Изучение проектного и продуктового подхода, основ бизнес и системного анализа, оценки целесообразности проекта и управление рисками',
//                     modules: {
//                         title: 'Модули',
//                         values: [
//                             "Введение в искусственный интеллект (ИИ), машинное обучение, большие данные и управление ",
//                             'Проектирование продукта и управление проектом',
//                             'Гибкие навыки менеджера проектов ',
//                             'Гибкие навыки менеджера проектов ',
//                             'Карьера менеджера проектов в области ИИ',
//                             'Технология анализа данных и ИИ'
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.gaming,
//                     url: '/'
//                 },
//             ],

//         },
//         trajectories: {
//             title: 'Гибкие образовательные траектории',
//             target: 'заключается в предоставлении гражданам возможности получения ДПО в области больших данных, ИИ и смежных с ними направлениях. ',
//             whocan: {
//                 title: "Кто может обучаться?",
//                 values: [
//                     'Преподаватели',
//                     'Граждане, достигшие 18 лет не ниже СПО',
//                     'Студенты ВУЗов тех.специальностей',
//                     'Управленцы различных уровней'
//                 ]
//             },
//             approaches: {
//                 title: 'Ключевые подходы',
//                 values: [{
//                     text: 'Персональное сопровождение и поддержка обучаемых',
//                     icon: imports.done
//                 },
//                 {
//                     text: 'Дистанционный формат, удобный график и темп обучения',
//                     icon: imports.education
//                 },
//                 {
//                     text: 'Использование современного программного обеспечения',
//                     icon: imports.business
//                 },
//                 {
//                     text: 'Передовые и востребованные методы, работа с БД',
//                     icon: imports.business
//                 },

//                 ]
//             }
//         },
//         advantages: {
//             title: "Преимущества",
//             values: [{
//                 title: "Ставьте свои рамки обучения",
//                 desc: 'Всего за 3 месяца обучение вы сможете получить базовые знания при этом выделяя всего по 2–3 часа в день',
//                 image: imports.teenagers
//             },
//             {
//                 title: "Удобное и бесплатное обучение",
//                 desc: 'Удобное и бесплатное обучение в онлайн формате, которое дает возможность обучаться с любой точки мира',
//                 image: imports.tasks
//             },
//             {
//                 title: "Удостоверение по окончании",
//                 desc: 'Даем возможность подтверждить свои знания и навыки госудаственным документом',
//                 image: imports.educ
//             },
//             {
//                 title: "Высокая восстребованность",
//                 desc: 'Это возможность для получения высокооплачиваемых и перспективных рабочих мест',
//                 image: imports.educ
//             },
//             ]
//         },
//         results: {
//             title: "Что получите?",
//             color: 'orange',

//             withPhotos: [{
//                 desc: 'Можно осваивать параллельно с получением образования, основной работой без отрыва от производства',
//                 image: imports.up
//             },
//             {
//                 desc: 'В основе — передовые и наиболее востребованные методы, приёмы, технологии работы с большими данными',
//                 image: imports.teacher
//             },
//             {
//                 desc: 'Использование современного программного обеспечения в своих работах и проэктах',
//                 image: imports.part
//             },
//             {
//                 desc: 'Дистанционный формат, удобный график и темп обучения и интерактивный формат подачи материала',
//                 image: imports.success
//             },
//             {
//                 desc: 'Персональное сопровождение и поддержка обучаемых на всех этапах обучения',
//                 image: imports.success
//             },

//             ],
//             withOutPhotos: [{
//                 icon: imports.education,
//                 text: '3 месяца обучения по 2-3 часа в день. Всего 260 часов',
//             },
//             {
//                 icon: imports.cert,
//                 text: 'По окончании диплом о профессиональной переподготовке',
//             },
//             {
//                 icon: imports.business,
//                 text: 'Перспективная и востребованная профессия',
//             }]
//         },
//         roadmap: {
//             title: 'Ваша дорожная карта',
//             values: [{
//                 desc: 'Заполнение предварительной заявки на обучение;'
//             },
//             {
//                 desc: 'Регистрация на Leader-ID;'
//             },
//             {
//                 desc: 'Подготовка документов для регистрации',
//                 tooltip: `- паспорт, 
// - СНИЛС,
// - Документ об образовании,
// - Справка с места обучения (для студентов);`
//             },
//             {
//                 desc: 'Для подачи заявки пройдите по ссылке и заполните анкету'
//             },
//             {
//                 desc: 'Отслеживайте статус проверки вашей анкеты',
//                 tooltip: 'Как только она будет проверена в НТИ, необходимо оперативно пройти Диагностику - Вступительное испытание в виде теста из 20-ти вопросов;'
//             },
//             {
//                 desc: 'Успешное прохождение испытайний: выбор курса'
//             },
//             {
//                 desc: 'У курсов будет 2 потока. Выбрать поток изначально нельзя. Для того, чтобы записаться во 2 поток, необходимо сообщить о своем желании ',
//                 tooltip: 'Запись производиться на почту data@1t.ru, с указанием ФИО и почты, с которой была произведена регистрация на курс'
//             },
//             ]
//         },
//         qustions: {
//             title: 'Частые вопросы',
//             values: [{
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             }]
//         },
//         feeback: {
//             title: 'Не получилось найти ответ на свой вопрос?',
//             desc: 'Оставтье свои данные. Мы свяжется с вами, ответим на все вопросы подробно и расскажем обо всех преимуществах программы'
//         }
//     },
//     'demografia': {
//         titles: {
//             title: 'Программа «Демография»',
//             description: 'Курсы «Демография» направлена на получение гражданами бесплатного дополнительного профессионального образования и повышение квалификации для педагогов в области анализа данных, программирования и работе с нейронными, а так же разработке игр'
//         },
//         programs: {
//             title: "Программы",
//             slides: [
//                 {
//                     title: "Преподавание разработки игр на JavaScript",
//                     description: 'Практический курс обучает написанию программного кода на JavaScript по программированию компьютерной игры. Выпускники умеют реализовывать пользовательский интерфейс, проектировать архитектуру игры, применять алгоритмы, программные инструменты в контексте игровой логики.',
//                     modules: {
//                         title: 'Модули',
//                         values: [
//                             "Введение в разработку игр",
//                             'Основы програмирования JavaScript',
//                             'Программа курса «Моделирование игровых миров на JavaScript» и ее реализация',
//                             'Прогамирование игровой логик',
//                             'Програмирование ИИ'
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.bpla,
//                     url: '/'
//                 },
//                 {
//                     title: "Преподавание основ нейронных сетей и Python",
//                     description: 'Практический курс учит создавать нейронные сети на языке программирования Python, а также обучать их и оптимизировать. Выпускники владеют навыками использования виджетов, библиотек Python, создания интерфейса нейросети.',
//                     modules: {
//                         title: 'Модули',
//                         values: [
//                             "Знакомство с Python и нейросетями",
//                             'Создание графических приложений и решение прикладных задач',
//                             'Создание и обучение нейронных сетей',
//                             'ООП на Python',
//                             'Программа курса «Разработка нейронных сетей на Python» и ее реализация'
//                         ],
//                         icon: imports.star
//                     },
//                     image: imports.gaming,
//                     url: '/'
//                 },
//             ],

//         },
//         trajectories: {
//             title: 'Гибкие образовательные траектории',
//             target: 'заключается в предоставлении гражданам возможности получения ДПО в области ИИ, нейронных сетях и программированию компьютерных игр на JavaScript.',
//             whocan: {
//                 title: "Кто может обучаться?",
//                 values: [
//                     'Преподаватели',
//                     'Граждане, имеющие СПО или высшее образование',
//                     'Кто интересуется программированием и IT разработкой',
//                     'Внимателен, усидчив и не ищет готовых решений'
//                 ]
//             },
//             approaches: {
//                 title: 'Ключевые подходы',
//                 values: [{
//                     text: 'Персональное сопровождение и поддержка обучаемых',
//                     icon: imports.done
//                 },
//                 {
//                     text: 'Для начала обучения не требуется начальных знаний и навыков',
//                     icon: imports.education
//                 },
//                 {
//                     text: 'Дистанционный формат, удобный график и темп обучения',
//                     icon: imports.business
//                 },


//                 ]
//             }
//         },
//         advantages: {
//             title: "Преимущества",
//             values: [{
//                 title: "Ставьте свои рамки обучения",
//                 desc: 'Всего за 3 месяца обучение вы сможете получить базовые знания при этом выделяя всего по 2–3 часа в день',
//                 image: imports.teenagers
//             },
//             {
//                 title: "Удобное и бесплатное обучение",
//                 desc: 'Удобное и бесплатное обучение в онлайн формате, которое дает возможность обучаться с любой точки мира',
//                 image: imports.tasks
//             },
//             {
//                 title: "Удостоверение по окончании",
//                 desc: 'Даем возможность подтверждить свои знания и навыки госудаственным документом',
//                 image: imports.educ
//             },
//             {
//                 title: "Высокая восстребованность",
//                 desc: 'Это возможность для получения высокооплачиваемых и перспективных рабочих мест',
//                 image: imports.educ
//             },
//             ]
//         },
//         results: {
//             title: "Что получите?",
//             color: 'orange',
//             withPhotos: [{
//                 desc: 'Можно осваивать параллельно с получением образования, основной работой без отрыва от производства',
//                 image: imports.up
//             },
//             {
//                 desc: 'В основе — передовые и наиболее востребованные методы, приёмы, технологии работы с большими данными',
//                 image: imports.teacher
//             },
//             {
//                 desc: 'Использование современного программного обеспечения в своих работах и проэктах',
//                 image: imports.part
//             },
//             {
//                 desc: 'Дистанционный формат, удобный график и темп обучения и интерактивный формат подачи материала',
//                 image: imports.success
//             },
//             {
//                 desc: 'Персональное сопровождение и поддержка обучаемых на всех этапах обучения',
//                 image: imports.success
//             },

//             ],
//             withOutPhotos: [{
//                 icon: imports.education,
//                 text: '3 месяца обучения по 2-3 часа в день. Всего 260 часов',
//             },
//             {
//                 icon: imports.cert,
//                 text: 'По окончании диплом о профессиональной переподготовке',
//             },
//             {
//                 icon: imports.business,
//                 text: 'Перспективная и востребованная профессия',
//             }]
//         },
//         roadmap: {
//             title: 'Ваша дорожная карта',
//             values: [{
//                 desc: 'Заполнение предварительной заявки на обучение;'
//             },
//             {
//                 desc: 'Регистрация на Leader-ID;'
//             },
//             {
//                 desc: 'Подготовка документов для регистрации',
//                 tooltip: `- паспорт, 
// - СНИЛС,
// - Документ об образовании,
// - Справка с места обучения (для студентов);`
//             },
//             {
//                 desc: 'Для подачи заявки пройдите по ссылке и заполните анкету'
//             },
//             {
//                 desc: 'Отслеживайте статус проверки вашей анкеты',
//                 tooltip: 'Как только она будет проверена в НТИ, необходимо оперативно пройти Диагностику - Вступительное испытание в виде теста из 20-ти вопросов;'
//             },
//             {
//                 desc: 'Успешное прохождение испытайний: выбор курса'
//             },
//             {
//                 desc: 'У курсов будет 2 потока. Выбрать поток изначально нельзя. Для того, чтобы записаться во 2 поток, необходимо сообщить о своем желании ',
//                 tooltip: 'Запись производиться на почту data@1t.ru, с указанием ФИО и почты, с которой была произведена регистрация на курс'
//             },
//             ]
//         },
//         qustions: {
//             title: 'Частые вопросы',
//             values: [{
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             },
//             {
//                 question: 'Вопрос',
//                 answer: 'Ответ'
//             }]
//         },
//         feeback: {
//             title: 'Не получилось найти ответ на свой вопрос?',
//             desc: 'Оставтье свои данные. Мы свяжется с вами, ответим на все вопросы подробно и расскажем обо всех преимуществах программы'
//         }
//     },
// }

const programmName: { [key: string]: string } = {
    bas: 'БАС',
    kodbudushchego: 'Код будущего',
    Iskusstvennyjintellekt: "Искусственный интеллект",
    Demografiya: "Демография"
}

type IType = 'bas' | 'kodbudushchego' | 'Iskusstvennyjintellekt' | 'Demografiya'

const ProgramTemplate: FunctionComponent<ProgramTemplateProps> = () => {
    let { program } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [loading, setLoading] = useState(true)
    const [datas, setData] = useState({})
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/5')
            .then(res => {
                // console.log(res);
                
                const newData: Record<string, any> = {};

                for (const [key, value] of Object.entries(res.data.object)) {
                    const parsedItems = JSON.parse(value as string);
                    parsedItems.forEach((item: any) => {
                        newData[key] = [...(newData[key] || []), item];
                    });
                }
                const keysProgramm = newData.keys.map((item: any) => {
                    return item.key;
                });

                setData(groupByKeys(keysProgramm, newData));
                
                setLoading(false);
            });
    }, []);

    function groupByKeys(keys: string[], data: Record<string, any[]>): Record<string, any> {

        const result: Record<string, any> = {};

        keys.forEach(key => {
            result[key] = {};

            Object.keys(data).forEach(dataKey => {
                const filteredData = data[dataKey].filter(item => item.key === key);

                if (dataKey === "online_course") {
                    filteredData.forEach(item => {
                        if (item.modules) {
                            item.modules = JSON.parse(item.modules);
                        }
                    });
                }

                result[key][dataKey] = filteredData.length === 1 && dataKey !== 'dop_info' ? filteredData[0] : filteredData;
            });
        });


        return result;
    }
    if (loading) {
        return <LoadingSpinner />
    } else {
        console.log(datas);
        return (
            Object.keys(datas).includes(program!) ?
                <>
                    <main className="font-helio">
                        {/* <imports.Titles data={data[program! as IType].titles} /> */}
                        <imports.Titles data={ (datas as any)[program! as IType].info_kb } name={ (datas as any)[program! as IType].programs_array.name} />

                        {/* <imports.Programs data={data[program! as IType].programs} /> */}
                        <imports.Programs data={{ title: "Онлайн-курсы", slides: (datas as any)[program! as IType].online_course }} />
                        {/* <imports.Trajectories data={data[program! as IType].trajectories} /> */}

                        <imports.Trajectories data={{
                            title: 'Гибкие образовательные траектории',
                            target: (datas as any)[program! as IType].target.text,
                            whocan: {title: 'Кто может обучаться?', values: JSON.parse((datas as any)[program! as IType].who_can_educate.array)},
                            approaches : {title : 'Ключевые подходы', values: JSON.parse((datas as any)[program! as IType].keys.text)}
                            
                        }}/>
                        {/* <imports.Advantages data={data[program! as IType].advantages} /> */}
                        <imports.Advantages data={{
                            title: 'Преимущества',
                            values: JSON.parse((datas as any)[program! as IType].adventages_program.adventages_array),
                            } }/>
                        {/* <imports.Results data={data[program! as IType].results} /> */}
                        <imports.Results data={{
                                    title: 'Что получите?',
                                    withPhotos: JSON.parse((datas as any)[program! as IType].results.withPhotos),
                                    withOutPhotos : JSON.parse((datas as any)[program! as IType].results.withOutPhotos),
                                }} />

                        {/* <imports.Roadmap data={data[program! as IType].roadmap} /> */}
                        <imports.Roadmap data={{
                            title:'Ваша дорожная карта',
                            values: JSON.parse((datas as any)[program! as IType].road_map.road_map_array),
                        }} />
                        <imports.Additionalinfo data={{values: (datas as any)[program! as IType].dop_info}} />
                        {/* <imports.Questions data={data[program! as IType].qustions} /> */}
                        <imports.Questions data={{
                            title: 'Часто задаваемые вопросы',
                            values: JSON.parse((datas as any)[program! as IType].faq.faq_array),
                        }} />
                    </main>
                    <imports.Feedback data={(datas as any)[program! as IType].feedback_form} />
                </>
                :
                <NotFoundPage />
        );
    }
}

export default ProgramTemplate;