import { FunctionComponent } from "react";
import orangequestionmark from './../../../images/icons/orangequestionmark.svg'
import { Tooltip } from "flowbite-react";


interface RoadmapProps {
    data: {
        title: string,
        values: {
            title?: string,
            text: string,
            textIcon?: string
        }[]
    }
}

const theme = {
    "target": "w-fit",
    "animation": "transition-opacity",
    "arrow": {
        "base": "absolute z-10 h-2 w-2 rotate-45",
        "style": {
            "dark": "bg-gray-900 dark:bg-gray-700",
            "light": "bg-white",
            "auto": "bg-white dark:bg-gray-700"
        },
        "placement": "-4px"
    },
    "base": "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm",
    "hidden": "invisible opacity-0",
    "style": {
        "dark": "bg-orange text-white dark:bg-gray-700",
        "light": "border border-gray-200 bg-white text-gray-900",
        "auto": "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
    },
    "content": "relative z-20"
}

const TextTooltip = ({ text, url }: { text: string, url?:string }) => {
    return <div className="flex flex-col gap-1 items-start font-helio max-w-[600px]">
        {url ? 
            <pre className='font-helio'>
                <a href={url} className="underline">
                    {text}
                </a> 
            </pre>    
            :
        <pre className='font-helio'>
            {text}
        </pre>
}
    </div>
}


const Roadmap: FunctionComponent<RoadmapProps> = ({ data }) => {    
    return (
        <section className="bg-black-50">
            <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                {data.title}
            </h1>
            <ul className="mx-auto mt-12 grid grid-cols-1 gap-10 sm:mt-16 lg:mt-20 lg:grid-cols-4">
                {
                    data.values.map((item, index) => {

                        return index < 4 && <li key={index} className="flex-start group relative flex lg:flex-col">
                            <span
                                className={` absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-orange lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]`}
                                aria-hidden="true"></span>
                            <div
                                className="inline-flex h-10 w-10 shrink-0 font-bold items-center justify-center rounded-xl text-white bg-orange transition-all duration-200 group-hover:border-gray-900 group-hover:bg-black-900">
                                {'0' + (index + 1)}
                            </div>
                            <div className="lg:ml-6 md:ml-5 sm:ml-4 ml-3 lg:ml-0 lg:mt-4">
                                {/* {item.title &&
                                    <h3
                                        className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                        {item.title}
                                    </h3>} */}
                                <h4 className="text-base text-gray-700 flex flex-row relative justify-between h-full gap-2 items-center max-[1023px]:!flex-col max-[1023px]:!items-start">
                                    {item.text}
                                    {item.textIcon &&
                                        <>
                                        <div className="relative max-[1023px]:hidden">
                                            <Tooltip theme={theme} content={<TextTooltip text={item.textIcon} url={item.url}/>} style="dark" trigger="hover">
                                                <div className={`w-[30px] h-[30px] rounded-full bg-[#FE8016]  flex items-center justify-center text-white`}>
                                                    ?
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className="relative min-[1023px]:hidden text-[#505050] md:text-sm text-[12px]">
                                            Уточнение: <TextTooltip text={item.textIcon} url={item.url}/>
                                        </div>
                                        </>
                                    }
                                </h4>
                            </div>
                        </li>
                    })
                }
            </ul>
            <ul className="mx-auto mt-12 grid grid-cols-1 gap-10 sm:mt-16 lg:mt-20 lg:grid-cols-4">
                {
                    data.values.map((item, index) => {

                        return index > 3 && <li key={index} className="flex-start group relative flex lg:flex-col">
                            <span
                                className={`absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-orange lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]`}
                                aria-hidden="true"
                            >
                            </span>
                            <div
                                className="inline-flex h-10 w-10 shrink-0 font-bold items-center justify-center rounded-xl text-white bg-orange transition-all duration-200 group-hover:border-gray-900 group-hover:bg-black-900">
                                {'0' + (index + 1)}
                            </div>
                            <div className="lg:ml-6 md:ml-5 sm:ml-4 ml-3 lg:ml-0 lg:mt-4">
                                {/* <h3
                                    className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                    {item.title}
                                </h3> */}
                                <h4 className="text-base text-gray-700 flex flex-row relative justify-between h-full gap-2 items-center max-[1023px]:!flex-col max-[1023px]:!items-start">
                                {item.text}
                                    {item.textIcon &&
                                        <>
                                        <div className="relative max-[1023px]:hidden">
                                            <Tooltip theme={theme} content={<TextTooltip text={item.textIcon} url={item.url}/>} style="dark" trigger="hover">
                                                <div className={`w-[30px] h-[30px] rounded-full bg-[#FE8016]  flex items-center justify-center text-white`}>
                                                    ?
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className="relative min-[1023px]:hidden text-[#505050] md:text-sm text-[12px]">
                                            Уточнение: <TextTooltip text={item.textIcon} url={item.url}/>
                                        </div>
                                        </>
                                    }
                                </h4>
                            </div>
                        </li>
                    })
                }
            </ul>
        </section>
    );
}

export default Roadmap;