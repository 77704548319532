import React, { memo, useEffect, useState } from 'react';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';
import { useParams } from 'react-router';
import axios from 'axios';
import { NewsRes } from '../News'
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import { imageURL } from '../../main/Main';

const ItemNews = memo(() => {
    let { id } = useParams();
    const [data, setData] = useState<NewsRes>()
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/3')
            .then(res => {
                for (const [key, value] of Object.entries(res.data.object)) {
                    // console.log('key', key, JSON.parse(value as string));
                    if (key === 'news') {
                        const allNews = JSON.parse(value as string);
                        setData(allNews.find((i: NewsRes) => Number(i.id) === Number(id)));
                    }

                }
                setLoading(false)
            })
        window.scrollTo(0, 0);
    }, [])

    return (
        loading ? <LoadingSpinner /> : <main>
            <section className="pt-28 font-helio h-[100%]">
                <div className='flex flex-row justify-between gap-1 items-center w-full pb-[24px]'>
                    <h1 className="text-orange lg:text-[48px] md:text-[40px] sm:text-3xl text-2xl lg:!leading-[60px] font-bold  cursor-pointer">
                        {data?.title}
                    </h1>
                    <span className="lg:text-[24px] md:text-base sm:text-sm text-sm font-helio_c">
                        {data?.date}
                    </span>
                </div>

                <Swiper
                    scrollbar={{
                        hide: false,
                        draggable: true,
                    }}
                    slidesPerView={'auto'}
                    autoHeight={true}
                    spaceBetween={10}
                    modules={[Scrollbar]}
                    className="items-stretch flex-grow"
                    pagination={{ clickable: true }}
                    style={{
                        "--swiper-pagination-color": "#FE8016",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                        "--swiper-pagination-bullet-horizontal-gap": "6px",
                        "--swiper-pagination-bullet-vertical-margin": "116px",
                    } as any}
                >
                    {
                        //    @ts-ignore
                        data?.images ? JSON.parse(data?.images).map((item, index) => {
                            return <SwiperSlide key={index} className=" pb-10 flex-grow items-stretch">
                                <img src={imageURL + item.image} alt="" className="min-h-[317px] h-auto" />
                            </SwiperSlide>
                        }) : <span className='bg-gray h-[317px] w-[370px]' />
                    }
                </Swiper>



                <p className="lg:text-[24px] md:text-base sm:text-sm text-sm font-helio_c lg:mt-6 md:mt-4 sm:mt-3 mt-3">
                    {data?.desc}
                </p>
            </section>
        </main>
    );
});

export default ItemNews;



