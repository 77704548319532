import { FunctionComponent } from "react";
import { useSwiper } from "swiper/react";
import leftarrow from '../../../images/icons/leftarrow.svg'

interface PrevButttonProps {
    background: string
}

const PrevButtton: FunctionComponent<PrevButttonProps> = ({ background }) => {
    const swiper = useSwiper()
    return (
        <div className={`min-w-[50px] h-[50px] flex items-center justify-center hover:opacity-70 rounded-full cursor-pointer bg-[${background}]`} style={{backgroundColor: background}} onClick={() => swiper.slidePrev()} >
            <img src={leftarrow} alt="" className="w-1/2 h-1/2" />
        </div>
    );
}

export default PrevButtton;