import { FunctionComponent, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import questionImage from '../../../images/question.png'
import simplearrowwhite from '../../../images/icons/simplearrowwhite.svg'

interface QuestionsProps {
    data: {
        title: string,
        values: {
            question: string,
            answer: string
        }[]
    }
}


const Questions: FunctionComponent<QuestionsProps> = ({ data }) => {
    const [currentQuestion, setCurrentQuestion] = useState<number | null>(null)
    return (
        data.values &&
        <section className='flex flex-col lg:gap-13 md:gap-13 sm:gap-8 gap-8 font-helio'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left">
                    {data.title}
                </h1>
            </ScrollAnimation>
            <div className="flex flex-col gap-3 overflow-y-auto">
                {data.values.map((item, index) => {
                    return (
                        <div className={`rounded-2xl transition-all ${currentQuestion == index ? 'bg-orange' : 'bg-transparent'}`} key={index}>
                            <div className="lg:text-[28px] cursor-pointer md:text-2xl sm:text-xl text-base lg:p-4 md:p-3 p-2 border-2 border-orange flex flex-row items-center justify-between rounded-2xl bg-white " onClick={() => setCurrentQuestion(prev => prev == index ? null : index)}>
                                <span>
                                    {item.question}
                                </span>
                                <span className={`${currentQuestion == index ? 'rotate-90' : 'rotate-0'} text-center min-w-[20px] w-9 h-9 leading-8 text-3xl text-white  rounded-full bg-orange transition-all`}>
                                    +
                                </span>
                            </div>
                            <div key={index} className={`grid ${currentQuestion == index ? 'grid-rows-[1fr] p-2 bg-orange' : 'grid-rows-[0fr]'} rounded-2xl overflow-hidden transition-all text-white lg:text-lg md:text-base sm:text-sm text-[12px]`}>
                                <div className="min-h-0">
                                    {item.answer}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    );
}

export default Questions;