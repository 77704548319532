import { Fragment, FunctionComponent } from "react";
import samo from '../../../images/icons/delivery/samo.svg'
import samotrans from '../../../images/icons/delivery/samotrans.svg'
import trans from '../../../images/icons/delivery/trans.svg'
import courier from '../../../images/icons/delivery/courier.svg'
import ScrollAnimation from "react-animate-on-scroll";
import transImage from '../../../images/delivery/trans.png'
import sdek from '../../../images/delivery/sdek.png'
import delo from '../../../images/delivery/delo.png'
import pek from '../../../images/delivery/pek.png'
import { imageURL } from "../../main/Main";


interface DeliveryProps {
    desc: string,
    images: string,
    items: string
}


const Delivery = ({ data }: { data: DeliveryProps[] }) => {
    console.log(JSON.parse(data[0].images));

    return (
        <section className='flex flex-col lg:gap-13 md:gap-13 sm:gap-8 gap-8 font-helio'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left ">
                    Доставка
                </h1>
            </ScrollAnimation>
            {data.map((item, index: number) => {
                return <Fragment key={index}>
                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4">
                        {
                            JSON.parse(item.items).map((item: any, index: number) => {
                                return <div key={index} className="lg:p-7 md:p-5 sm:p-4 p-3 bg-pink flex flex-col gap-3 items-start rounded-xl">
                                    <img src={imageURL + item.icon} alt="" className="max-w-[42px] h-[41px] max-[500px]:max-w-[20px] max-[500px]:h-[20px] " />
                                    <span className="lg:text-2xl md:text-xl sm:text-base text-sm text-white">
                                        {item.text}
                                    </span>
                                </div>
                            })
                        }
                    </div>
                    <div>
                        <span className="text-black lg:text-2xl md:text-xl sm:text-base text-sm">
                            Для удобства наших клиентов мы доставляем заказанные вами товары бесплатно в пределах г. Красноярка.
                            Для иногородних клиентов отправим груз выбранной вами транспортной компанией, или сами подберём оптимальную транспортную организацию по соотношению цена доставки/время ожидания груза.
                        </span>
                    </div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4">
                        {JSON.parse(item.images).map((item: any, index:number) => {
                            return <img key={index} src={imageURL + item.image} alt="" />
                        })}
                    </div>
                </Fragment>
            })}
        </section>
    );
}

export default Delivery;