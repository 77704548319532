import { FunctionComponent, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import komp from '../../../images/icons/group/komp.svg'
import network from '../../../images/icons/group/network.svg'
import office from '../../../images/icons/group/office.svg'
import pencil from '../../../images/icons/group/pencil.svg'
import po from '../../../images/icons/group/po.svg'
import projector from '../../../images/icons/group/projector.svg'
import server from '../../../images/icons/group/server.svg'
import sofa from '../../../images/icons/group/sofa.svg'
import video from '../../../images/icons/group/video.svg'
import materials from '../../../images/icons/group/materials.svg'
import tech from '../../../images/icons/group/tech.svg'
import power from '../../../images/icons/group/power.svg'
import globe from '../../../images/icons/group/globe.svg'
import hometech from '../../../images/icons/group/hometech.svg'
import sport from '../../../images/icons/group/sport.svg'
import equipment from '../../../images/icons/group/equipment.svg'
import { imageURL } from "../../main/Main";
import pinkarrow from '../../../images/icons/pinkarrow.svg'


interface GroupProps {
    text: string,
    icon: string,
    desc: string
}

const Group = ({ data }: { data: GroupProps[] }) => {


    const [currentQuestion, setCurrentQuestion] = useState<number | null>(null)

    const [show, setShow] = useState<boolean>(false)
    return (
        <section className='flex flex-col lg:gap-16 md:gap-13 sm:gap-8 gap-8'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                    Группы поставляемых ресурсов
                </h1>
            </ScrollAnimation>
            <div className={`grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  grid-cols-1  lg:gap-10 md:gap-7 sm:gap-4 gap-4 items-baseline overflow-hidden transition-all`}>
                {data.map((item, index) => {
                    return <div className={`flex-col ${currentQuestion == index ? 'bg-pink ' : ''} rounded-2xl transition-all lg:${!show ? index > 7 ? 'hidden' : 'flex' : 'flex'} ${!show ? index > 3 ? 'hidden' : 'flex' : 'flex'} `} key={index}>
                        <div onClick={() => setCurrentQuestion(prev => prev == index ? null : index)} className="flex cursor-pointer flex-col bg-white p-3  max-[640px]:flex-row max-[640px]:items-center max-[640px]:justify-between max-[640px]:border-2 max-[640px]:border-pink  max-[640px]:rounded-2xl max-[640px]:w-full max-[640px]:max-w-none">
                            <div className="flex flex-col ">
                                <img src={imageURL + item.icon} alt="" className="w-1/5 max-[640px]:w-[30px] max-[640px]:h-[30px]" />
                                <span className="lg:text-2xl md:text-xl sm:text-sm text-sm font-helio w-[90%] lg:mt-6 md:mt-4 sm:mt-2 mt-2 max-[640px]:w-3/4">
                                    {item.text}
                                </span>
                            </div>
                            <div className={`flex flex-col transition-all ${currentQuestion == index ? 'bg-pink rounded-2xl min-[640px]:p-2' : ''} `}>
                                <span className={`max-[640px]:hidden text-sm  font-bold ${currentQuestion == index ? 'text-white' : 'text-pink '}`}>
                                    Подробнее
                                </span>
                                <img src={pinkarrow} onClick={() => setCurrentQuestion(prev => prev == index ? null : index)} className={`min-[640px]:hidden transition-all cursor-pointer ${currentQuestion == index ? 'rotate-90' : 'rotate-0'}`} alt="" />
                                
                                <div key={index} className={`grid max-[640px]:hidden ${currentQuestion == index ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'} rounded-2xl overflow-hidden transition-all text-white lg:text-lg md:text-base sm:text-sm text-[12px]`}>
                                    <span className="min-h-0">
                                        {item.desc}
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div key={index} className={`grid min-[640px]:hidden ${currentQuestion == index ? 'grid-rows-[1fr] p-3' : 'grid-rows-[0fr]'} rounded-2xl overflow-hidden transition-all text-white lg:text-lg md:text-base sm:text-sm text-[12px]`}>
                            <span className="min-h-0">
                                {item.desc}
                            </span>
                        </div>
                    </div>
                })}
            </div>
            <div className="flex items-center justify-center">
                <button className="lg:md:py-5 sm:py-3 lg:md:px-10 sm:px-6 px-6 py-3  border-[1px] border-pink rounded-full lg:text-2xl md:text-lg sm:text-sm text-[12px] font-helio hover:bg-pink hover:text-white transition-all" onClick={() => setShow(prev => !prev)}>
                    Все группы товаров
                </button>
            </div>
        </section>
    );
}

export default Group;