import { FunctionComponent } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import ScrollAnimation from 'react-animate-on-scroll';
import customBG from '../../../images/background.png'
import { Adventage } from "../types";


interface AdvantagesProps {

}


const Advantages = ({ data }: { data: Adventage[] }) => {
    return (
        <section className="custom-bg relative py-16 overflow-hidden max-[900px]:p-0 ">
            <img src={customBG} alt="" className="absolute top-0 left-0 w-full h-full max-[900px]:w-[200%] max-[900px]:-left-40 max-w-none z-1" />
            <div className="p-3">

                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}
                >
                    <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-24 md:mb-18 sm:mb-6 mb-6">
                        Преимущества
                    </h1>
                </ScrollAnimation>
                <div className="z-2">
                    <Swiper
                        scrollbar={{
                            hide: false,
                            draggable: true,

                        }}
                        slidesPerView={'auto'}
                        spaceBetween={30}
                        modules={[Scrollbar]}
                        className="z-2 red-swiper"
                    // breakpoints={{
                    //     1440: {
                    //         slidesPerView: 4,
                    //     },
                    //     1088: {
                    //         slidesPerView: 3,
                    //     },
                    //     768: {
                    //         slidesPerView: 2,
                    //     },
                    //     0:{
                    //         slidesPerView: 1
                    //     }
                    // }}
                    >
                        {
                            data.map((item, index) => {
                                return <SwiperSlide key={index} className="max-w-[340px]">
                                    <div className="flex flex-col items-start gap-4 p-4 border-2 h-[326px] max-[500px]:h-[250px] mb-14 border-red bg-white rounded-2xl max-w-[370px] group  hover:bg-red transition-all ">
                                        <span className="lg:text-2xl md:text-[22px] sm:text-[20px] text-[20px] font-bold font-helio text-[#D42E12] group-hover:text-white">
                                            {'0' + (index + 1)}
                                        </span>
                                        <span className="lg:text-2xl md:text-xl sm:text-lg text-lg text-left font-bold text-black font-helio group-hover:text-white">
                                            {item.text}
                                        </span>
                                        <span className="lg:text-lg md:text-base sm:text-sm text-sm font-[300] text-left text-black font-helio_c group-hover:text-white">
                                            {item.desc}
                                        </span>
                                    </div>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>

                </div>
            </div>
        </section>
    );
}

export default Advantages;