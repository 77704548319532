import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface itemNews {
    MIGX_id: number;
    date: string;
    desc: string;
    title: string
}

interface NewsProps {
    data: itemNews[]
}

// const data = [
//     {
//         title: 'Новость',
//         desc: 'Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.',
//         date: '05.10.2013'
//     },
//     {
//         title: 'Новость',
//         desc: 'Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.',
//         date: '05.10.2013'
//     }, {
//         title: 'Новость',
//         desc: 'Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.',
//         date: '05.10.2013'
//     }, {
//         title: 'Новость',
//         desc: 'Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.',
//         date: '05.10.2013'
//     }, {
//         title: 'Новость',
//         desc: 'Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.',
//         date: '05.10.2013'
//     },
// ]

const News: FunctionComponent<NewsProps> = (props: NewsProps) => {
    const { data } = props;
    return (
        <section>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                    Новости
                </h1>
            </ScrollAnimation>

                <Swiper
                    scrollbar={{
                        hide: false,
                        draggable: true,
                    }}
                    slidesPerView={'auto'}
                    spaceBetween={15}
                    modules={[Scrollbar]}
                >
                    {
                        data.map(item => {
                            return <SwiperSlide key={item.MIGX_id} className="max-w-[370px] ">
                                {/* @ts-ignore */}
                                <a href={`https://aversprofi.ru/news/${item.id}`} className="relative h-[380px] flex flex-col mb-14 lg:md:gap-4 gap-3 hover:bg-orange group hover:text-white items-start max-w-[501px] border-[1px] border-orange rounded-2xl lg:p-8 md:p-6 sm:p-4 p-4 font-helio">
                                    <span className="lg:text-2xl md:text-xl sm:text-base text-sm font-bold">
                                        {item.title}
                                    </span>
                                    <span className="lg:text-lg md:text-base max-h-[300px] !text-ellipsis  overflow-hidden sm:text-sm text-sm flex-grow font-helio_c">
                                        {item.desc}
                                    </span>
                                    <span className="text-[#5A5A5A] group-hover:text-[#E8E8E8] lg:text-base md:text-sm sm:text-[12px] text-[12px]">
                                        {item.date}
                                    </span>
                                </a>
                            </SwiperSlide>
                        })
                    }
                </Swiper> 
            {/* <span className="hideScrollbarMin:hidden z-2 inline">
                <Swiper
                    scrollbar={{
                        hide: false,
                        draggable: false,
                    }}
                    slidesPerView={'auto'}
                    spaceBetween={15}
                    modules={[Scrollbar]}
                >
                    {
                        data.map(item => {
                            return <SwiperSlide key={item.MIGX_id} className="max-w-[370px]">
                                <div className="relative flex flex-col mb-14 lg:md:gap-4 gap-3  items-start max-w-[501px] border-[1px] border-orange rounded-2xl lg:p-8 md:p-6 sm:p-4 p-4 font-helio">
                                    <span className="lg:text-2xl md:text-xl sm:text-base text-sm font-bold">
                                        {item.title}
                                    </span>
                                    <span className="lg:text-lg md:text-base sm:text-sm text-sm flex-grow font-helio_c">
                                        {item.desc}
                                    </span>
                                    <span className="text-[#5A5A5A] lg:text-base md:text-sm sm:text-[12px] text-[12px]">
                                        {item.date}
                                    </span>
                                </div>
                            </SwiperSlide>
                        })
                    }
                </Swiper></span> */}




            {/* <div className="flex flex-row gap-4 flex-wrap">
                {
                    data.map(item => {
                        return <div className="relative flex flex-col gap-4 items-start max-w-[501px] border-[1px] border-orange rounded-2xl p-6">
                            <span className="lg:text-2xl md:text-xl sm:text-base text-sm font-bold">
                                {item.title}
                            </span>
                            <span className="lg:text-2xl md:text-xl sm:text-base text-sm flex-grow ">
                                {item.desc}
                            </span>
                            <span>
                                {item.date}
                            </span>
                        </div>
                    })
                }
            </div> */}
        </section>
    );
}

export default News;