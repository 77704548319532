// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #FE8016; /* Цвет спиннера */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loader-text {
    margin-top: 10px;
    font-size: 18px;
    color: #FE8016; /* Цвет текста */
    animation: pulse 1.5s infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/loadingSpinner/spinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,6BAA6B,EAAE,kBAAkB;IACjD,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc,EAAE,gBAAgB;IAChC,8BAA8B;AAClC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;IAChB;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".spinner-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.loader {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.spinner {\n    width: 40px;\n    height: 40px;\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-top: 4px solid #FE8016; /* Цвет спиннера */\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n}\n\n.loader-text {\n    margin-top: 10px;\n    font-size: 18px;\n    color: #FE8016; /* Цвет текста */\n    animation: pulse 1.5s infinite;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n@keyframes pulse {\n    0% {\n        opacity: 1;\n    }\n    50% {\n        opacity: 0.5;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
