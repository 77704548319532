import { FunctionComponent } from "react";
import orangequestionmark from './../../../images/icons/orangequestionmark.svg'
import { Tooltip } from "flowbite-react";
import { log } from "console";

interface itemRoadmap{
    num: number,
    desc: string,
    title: string, 
} 

interface RoadmapProps {
    data: itemRoadmap[]
}

const theme = {
    "target": "w-fit",
    "animation": "transition-opacity",
    "arrow": {
        "base": "absolute z-10 h-2 w-2 rotate-45",
        "style": {
            "dark": "bg-gray-900 dark:bg-gray-700",
            "light": "bg-white",
            "auto": "bg-white dark:bg-gray-700"
        },
        "placement": "-4px"
    },
    "base": "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm",
    "hidden": "invisible opacity-0",
    "style": {
        "dark": "bg-orange text-white dark:bg-gray-700",
        "light": "border border-gray-200 bg-white text-gray-900",
        "auto": "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
    },
    "content": "relative z-20"
}

const Contacts = ({ text }: { text: string }) => {
    return <div className="flex flex-col gap-1 items-start">
        <pre>
            {text}
        </pre>
    </div>
}


const Stages: FunctionComponent<RoadmapProps> = ({ data }) => {
    console.log(data);
    
    
    return (
        <section className="bg-black-50">
            <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                Этапы работы
            </h1>
            <ul className="mx-auto mt-12 grid grid-cols-1 gap-10 sm:mt-16 lg:mt-20 lg:grid-cols-4">
                {
                    data.map((item, index) => {

                        return index < 4 && <li className="flex-start group relative flex lg:flex-col" key={index}>
                            <span
                                className={` absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-[#3269A4] lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]`}
                                aria-hidden="true"></span>
                            <div
                                className="inline-flex h-10 w-10 shrink-0 font-bold items-center justify-center rounded-xl text-white bg-[#3269A4] transition-all duration-200 group-hover:border-gray-900 group-hover:bg-black-900">
                                {'0' + (index + 1)}
                            </div>
                            <div className="ml-6 lg:ml-0 lg:mt-10">
                                {item.title &&
                                    <h3
                                        className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                        {item.title}
                                    </h3>}
                                <h4 className="mt-2 text-base text-gray-700 flex flex-row">
                                    {item.desc}
                                </h4>
                            </div>
                        </li>
                    })
                }
            </ul>
            <ul className="mx-auto mt-12 grid grid-cols-1 gap-10 sm:mt-16 lg:mt-20 lg:grid-cols-4">
                {
                    data.map((item, index) => {
                        return index > 3 && <li className="flex-start group relative flex lg:flex-col">
                            <span
                                className={`absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-[#3269A4] lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]`}
                                aria-hidden="true"
                            >
                            </span>
                            <div
                                className="inline-flex h-10 w-10 shrink-0 font-bold items-center justify-center rounded-xl text-white bg-[#3269A4] transition-all duration-200 group-hover:border-gray-900 group-hover:bg-black-900">
                                {'0' + (index + 1)}
                            </div>
                            <div className="ml-6 lg:ml-0 lg:mt-10">
                                <h3
                                    className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                    {item.title}
                                </h3>
                                <h4 className="mt-2 text-base text-gray-700">
                                    {item.desc}
                                </h4>
                            </div>
                        </li>
                    })
                }
            </ul>
        </section>
    );
}

export default Stages;