import { FunctionComponent, useEffect, useRef, useState } from "react";
import Banner from "./banner/Banner";
import Works from "./works/Works";
import Group from "./group/Group";
import Advantages from "./advantages/Advantages";
import Platforms from "./platforms/Platforms";
import Delivery from "./delivery/Delivery";
import Payment from "./payment/Payment";
import Feedback from "./feedback/Feedback";
import Steps from "./steps/Steps";
import axios from "axios";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import { useParams } from "react-router";

interface SupplyProps {

}

const Supply: FunctionComponent<SupplyProps> = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const step = useRef(null)
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/4')
            .then(res => {
                for (const [key, value] of Object.entries(res.data.object)) {
                    console.log(key);
                    if (value) {

                        console.log(JSON.parse(value as string) ?? '');
                        setData(prev => ({ ...prev, [key]: JSON.parse(value as string) ?? '' }))

                    }
                    else {
                        setData(prev => ({ ...prev, [key]: [] }))
                    }

                }
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        const idSection = window.location.hash.slice(1);
        if (idSection) {
            const element = document.getElementById(idSection);
            if (element) {
                element.scrollIntoView({ block: "start", behavior: 'smooth' });
            } else {
                console.error('элемента нету');
            }
            // document.getElementById("step3").scrollIntoView();
        } else {
            window.scrollTo(0, 0);
        }
    }, [loading])

    return (
        loading ? <LoadingSpinner /> :
            <>

                <main>
                    <Banner data={(data as any).banner} />
                    <Works data={(data as any).works_krd} />
                    <Group data={(data as any).groups} />
                    <Advantages data={(data as any).adventages_ks} />
                    <Steps
                        direct_contract={(data as any).direct_contract}
                        auction={(data as any).auction}
                        legal_entities={(data as any).legal_entities}
                        faulties={(data as any).faulties} />
                    <Platforms data={(data as any).platforms} />
                    <Delivery data={(data as any).delivery} />
                    <Payment data={(data as any).payment} />
                </main>

                <Feedback data={(data as any).feedback} />
            </>
    );
}

export default Supply;