import { FunctionComponent, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import questionImage from '../../../images/question.png'
import simplearrowwhite from '../../../images/icons/simplearrowwhite.svg'
import { imageURL } from "../../main/Main";
interface itemQuestions {
    MIGX_id: number;
    desc: string;
    title: string;
}

interface QuestionsProps {
    data: {
        image: string,
        question_array: string
    }[]
}


// const data = [
//     {
//         title: 'Технологическим компаниям',
//         desc: 'Сокращение расходов и сроков на подготовку кадров с новыми сочетаниями компетенций; Продвижение платформ и компетенций.'
//     },
//     {
//         title: 'Университетам',
//         desc: 'Быстрое внедрение новых образовательных форматов; Включенность в сеть организаций и цифровых платформ.'
//     },
//     {
//         title: 'Школам',
//         desc: 'Ранняя профориентация; Персонализация обучения; Доступ к раннему развитию цифровых навыков.'
//     },
//     {
//         title: 'Корпорациям',
//         desc: 'Быстрое обучение сотрудников новым компетенциям; Управление персонала на основе данных; Конкурентоспособная модель кадровой политики; Площадка для обмена опытом и знаниями; Решение задач бизнеса проектными командами интенсивов.'
//     },
//     {
//         title: 'Региональным властям',
//         desc: 'Анализ и развитие региональных систем образования; Быстрый импорт компетенций в регион.'
//     },
//     {
//         title: 'Студенту',
//         desc: 'Навык проектной деятельности; Доступ к лучшему контенту; Ориентация в новых технологиях и компетенциях.'
//     },
//     {
//         title: 'Специалисту, достигшему «профессионального потолка»',
//         desc: 'Смена курса профессионального развития; Поддержка шагов развития, «быстрые и дешёвые» ошибки; Ускоренное овладение дополнительными знаниями и навыками; Развитие мягких навыков.'
//     },
//     {
//         title: 'Школьнику',
//         desc: 'Осознанный выбор своего пути развития; Опережающее развитие цифровых навыков; Раннее развитие мягких навыков и эмоционального интеллекта; Выбор актуальной профессии и программы обучения, востребованных в цифровой экономике.'
//     },
//     {
//         title: 'Родителю',
//         desc: 'Навигация по востребованным навыкам и знаниям; Помощь в определении пути развития ребёнка; Раннее развитие мягких навыков и цифровых компетенций.'
//     },
//     {
//         title: 'Эксперту',
//         desc: 'Площадка для передачи знаний и опыта; Сообщество как среда для обогащения экспертизы и общения.'
//     },
//     {
//         title: 'Преподавателю',
//         desc: 'Включение в сеть и масштабирование своей деятельности; Анализ эффективности образовательных программ; Упрощение использования образовательного контента из сети Интернет.'
//     },
//     {
//         title: 'Профессионалу с теряющими актуальность навыками',
//         desc: 'Определение пути развития с учётом имеющегося опыта; Оптимальная траектория освоения новых видов деятельности.'
//     },
//     {
//         title: 'Специалисту в области ИКТ',
//         desc: 'Карта развития своих навыков; Создание и апробация новых инструментов для образования.'
//     },
//     {
//         title: 'Тому, кто в поиске: куда и как развиваться дальше',
//         desc: `Информация о новых и востребованных видах деятельности; Развитие рефлексивного отношения к шагам развития.`
//     },
// ]

const Questions: FunctionComponent<QuestionsProps> = (props: QuestionsProps) => {
    const { data } = props;
    console.log(data)
    const [currentQuestion, setCurrentQuestion] = useState<number | null>(null)
    return (
        <section className='flex flex-col lg:gap-13 md:gap-13 sm:gap-8 gap-8 font-helio'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                    Кому полезны курсы
                </h1>
            </ScrollAnimation>
            {data.map((data, index) => {
                return <div key={index} className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    <img src={imageURL + data.image} alt="" />
                    <div className="flex custom-scroll flex-col gap-3 overflow-y-auto h-[524px] lg:pr-[32px] md:pr-6 sm:pr-4 pr-3">
                        {JSON.parse(data.question_array).map((item: any, index: number) => {
                            return (
                                <div className={`rounded-2xl transition-all ${currentQuestion == index ? 'bg-orange' : 'bg-transparent'}`} key={index}>
                                    <div className="lg:text-[28px] cursor-pointer md:text-2xl sm:text-xl text-base p-3 border-2 border-orange flex flex-row items-center justify-between rounded-2xl bg-white " onClick={() => setCurrentQuestion(prev => prev == index ? null : index)}>
                                        <span>
                                            {item.title} 
                                        </span>
                                        <img src={simplearrowwhite} alt="" className={`${currentQuestion == index ? 'rotate-90' : 'rotate-0'} min-w-[20px] w-9 h-9 p-3 max-[1000px]:w-5 max-[1000px]:h-5 max-[1000px]:p-1 rounded-full bg-orange transition-all`} />
                                    </div>
                                    <div key={index} className={`grid ${currentQuestion == index ? 'grid-rows-[1fr] p-4 bg-orange' : 'grid-rows-[0fr]'} rounded-2xl overflow-hidden transition-all text-white lg:text-lg md:text-base sm:text-sm text-[12px]`}>
                                        <div className="min-h-0">
                                            {item.desc}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            })}
            
        </section>
    );
}

export default Questions;