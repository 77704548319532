import { FunctionComponent, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import InputMask from 'react-input-mask';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, FieldValues, useForm } from "react-hook-form";
import axios from "axios";
import { Checkbox, FormControlLabel } from "@mui/material";



interface FeedbackProps {
    data: {
        title: string,
        desc?: string,
        text?: string,
        email?: string,
    },
    color?: string;
}

const Feedback: FunctionComponent<FeedbackProps> = ({ data, color }) => {
    const [open, setOpen] = useState(false);
    type Inputs = {
        fio: string
        phone: string,
        email: string,
        consent: boolean
    }
    const { handleSubmit, control, register, formState: { errors } } = useForm<Inputs>();



    const onSubmit = async (data: FieldValues) => {
        console.log(data);
        await axios.get(`https://admin.aversprofi.ru/rest/DispatchBid/1?emailTo_1=burdin@avers.fm&emailTo_2=podashevko@avers.fm&type=contacts&phone=${data.phone}&name=${data.fio}&email=${data.email}`)
            .then(res => {
                console.log(res);
                setOpen(true);
            })
            .catch(e => {
                console.log(e);
            })
    }
    return (
        <section className={`flex flex-col lg:gap-13 md:gap-13 sm:gap-8 gap-8 font-helio py-28 ${color ? 'bg-[url("https://admin.aversprofi.ru/images/feedbackblue.png")]' : 'bg-[url("./images/feedbackbg.png")]'} min-[1000px]:bg-[length:100%_108%] bg-[length:1000%_103%]  bg-no-repeat`}>

            <div className="w-full flex lg:flex-row flex-col justify-around rounded-2xl gap-4 lg:py-20 md:py-14 sm:py-10 py-6 items-center flex-wrap max-w-[1776px] mx-auto">
                <div className="flex text-white flex-col gap-4 text-left items-start lg:w-2/5 w-full lg:p-0 px-6">
                    <h1 className="lg:text-[40px] md:text-[28px] sm:text-lg text-lg font-bold !leading-[1em]">
                        {data.title}
                    </h1>
                    <h2 className="lg:text-2xl md:text-xl sm:text-base text-[12px]">
                        {data.desc ? data.desc : data.text}
                    </h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="lg:w-2/6 w-full flex flex-col gap-3 lg:p-0 px-6">
                    <Controller
                        name="fio"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'ФИО обязательно' }}
                        render={({ field }) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="ФИО"
                                className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.fio ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                            />
                        )}
                    />
                    {errors.fio && <span className="text-red-500">{errors.fio.message}</span>}
                    <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Телефон обязателен' }}
                        render={({ field }) => (
                            <InputMask mask="+7(999)999-99-99" {...field}>
                                {/* @ts-ignore */}
                                {() => {
                                    return <input
                                        type="text"
                                        placeholder="Телефон"
                                        className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.phone ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                                    />
                                }}
                            </InputMask>
                        )}
                    />
                    {errors.phone && <span className="text-red-500">{errors.phone.message}</span>}
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Почта обязательна',
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: 'Некорректный формат почты'
                            }
                        }}
                        render={({ field }) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="Почта"
                                className={`lg:p-5 md:p-4 sm:p-3 p-2 border-[1px] ${errors.email ? 'border-red-500' : 'border-white'} lg:md:text-base sm:text-[12px] text-[10px] focus:border-orange outline-none focus:ring-orange focus:outline-none bg-white rounded-[10px] text-black w-full placeholder:text-[#BABABA]`}
                            />
                        )}
                    />
                    {errors.email && <span className="text-red-500">{errors.email.message}</span>}
                    <Controller
                        name="consent"
                        control={control}
                        defaultValue={false}
                        rules={{ required: 'Необходимо согласие' }}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} color='warning' />}
                                label="Нажимая на кнопку записи вы даёте согласие на обработку своих данных"
                                sx={{ mb: 2, color: 'white' }}
                            />
                        )}
                    />
                    {errors.consent && <span className="text-red-500">{errors.consent.message}</span>}
                    <button type='submit' className={`lg:md:text-base sm:text-[12px] text-[12px] ${color ? `text-[${color}]` : "text-orange"} text-center p-3 font-bold bg-white rounded-xl`}>
                        Проконсультироваться
                    </button>
                </form>

                <Snackbar
                    TransitionComponent={Fade}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={open}
                    onClose={() => setOpen(false)}
                    autoHideDuration={2000}
                >
                    <div>
                        <div className="flex flex-col gap-2 bg-[#FFFFFF] w-full shadow-lg max-w-[375px] justify-center text-black p-5 rounded-2xl">
                            <span className='text-base font-bold flex flex-row justify-between'>
                                Спасибо за обращение!

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => setOpen(false)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </span>
                            <span className="text-base">
                                Очень скоро свяжемся с вами. А пока вы можете подробно изучить нас сайт и ознакомиться с нашими программами
                            </span>
                        </div>
                    </div>
                </Snackbar>
            </div>
        </section>
    );
}

export default Feedback;