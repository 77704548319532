import { FunctionComponent } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import PrevButtton from "../../main/info/PrevButton";
import NextButton from "../../main/info/NextButton";
import { imageURL } from "../../main/Main";

interface ProgramsProps {
    data: {
        title: string,
        slides: {
            title: string,
            desc: string,
            modules: {
                MIGX_id: number,
                title: string,
                text: string,
                icon: string
            }[],
            image: string,
            url?: string
            File?: string
        }[]
    }
}

const Programs: FunctionComponent<ProgramsProps> = ({ data }) => {

    return (
        <section>
            <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left mb-6">
                {data.title}
            </h1>
            <Swiper
                modules={[Navigation, Pagination]}
                // slidesPerView={1}
                spaceBetween={100}
                autoHeight={true}
                className="modules-swiper"

                loop={true}
                pagination={{ clickable: true }}

                style={{
                    "--swiper-pagination-color": "#FE8016",
                    "--swiper-pagination-bullet-inactive-color": "#999999",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px",
                    "--swiper-pagination-bullet-vertical-margin": "116px",
                } as any}
            >
                {data.slides.map((item, index: number) => {
                    return <SwiperSlide key={index} data-color="#FE8016" className="max-[1025px]:mb-10 h-full items-stretch stretch">
                        <div className="flex flex-row gap-6 max-[1000px]:flex-col-reverse items-stretch">
                            <div className="rounded-[20px] flex justify-between flex-col items-stretch relative border-orange border-[1px] lg:p-8 md:p-6 sm:p-4 p-4 lg:gap-8 md:gap-6 sm:gap-4 gap-[11px]">
                                <div className="flex flex-col justify-start self-start lg:gap-6 md:gap-4 sm:gap-3 gap-[11px]">
                                    <h1 className="lg:text-[32px] md:text-[28px] sm:text-2xl text-xl font-bold">
                                        {item.title}
                                    </h1>
                                    <span className="font-helio_c lg:md:text-base sm:text-sm text-sm">
                                        {item.desc}
                                    </span>
                                </div>
                                <div className="flex flex-col justify-start self-start lg:gap-6 md:gap-4 sm:gap-3 gap-[11px]">
                                    <span className="lg:text-2xl md:text-xl sm:text-lg text-lg font-bold">
                                        {(item.modules && item.modules.length != 0) && 'Модули'}
                                    </span>
                                    <div className='flex flex-row gap-2 max-[1400px]:flex-wrap'>
                                        <div className="flex flex-col gap-2 w-full">
                                            {(item.modules && item.modules.length != 0) && item.modules.map((el, index) => {
                                                return index < (item.modules.length / 2) &&
                                                    <div key={index} className="flex flex-row gap-[9px] h-full items-center rounded-lg max-[1000px]:w-full min-[1000px]:min-w-[394px] max-w-[394px] border-[1px] border-orange p-2 before:content-[url(./images/icons/star.svg)] before:mt-2 before:flex before:justify-center before:items-center">
                                                        <span className="lg:text-base md:text-sm sm:text-[12px] text-[12px] font-helio_c">
                                                            {el.text}
                                                        </span>
                                                    </div>
                                            })}
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            {(item.modules && item.modules.length != 0) && item.modules.map((el, index) => {
                                                return index >= (item.modules.length / 2) &&
                                                    <div key={index} className="flex flex-row gap-[9px] h-full items-center rounded-lg max-[1000px]:w-full min-[1000px]:min-w-[394px] max-w-[394px] border-[1px] border-orange p-2 before:content-[url(./images/icons/star.svg)] before:mt-2 before:flex before:justify-center before:items-center">
                                                        <span className="lg:text-base md:text-sm sm:text-[12px] text-[12px] font-helio_c">
                                                            {el.text}
                                                        </span>
                                                    </div>
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="max-[1600px]:hidden flex flex-row absolute right-8 bottom-8 gap-4">
                                    <PrevButtton background='#FE8016' />
                                    <NextButton background='#FE8016' />
                                </div>
                            </div>
                            <div className="relative lg:py-6 md:py-4 sm:p-3 p-3 lg:px-[52px] md:px-11 sm:px-[34px] px-[34px] flex items-center justify-center bg-[#F8F8F8] rounded-[20px] min-[1000px]:min-w-[501px]">
                                <img src={imageURL + item.image} alt="" />
                                {item.url && <span>{item.url}</span>}
                                {item.File &&
                                    <a href={imageURL + item.File} className="lg:text-base md:text-sm sm:text-[10px] text-[10px] absolute bottom-4 left-4 gap-2 flex flex-row items-center lg:p-3 md:p-2 sm:p-1 p-1 hover:bg-orange hover:text-white bg-white rounded-[10px] hover:before:content-[url(./images/icons/whitepresentation.svg)] before:content-[url(./images/icons/presentation.svg)] before:mt-2 before:flex before:justify-center before:items-center">
                                        Презентация программы
                                    </a>
                                }
                            </div>

                        </div>
                    </SwiperSlide>
                })}

            </Swiper>
        </section>
    );
}

export default Programs;