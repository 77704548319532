import React, { memo, useEffect, useState } from 'react';
import { Feedback, Roadmap, Titles } from '../programs/imports';
import TargetsComponent from './components/TargetsComponent';
import axios from 'axios';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import Stages from './components/Stages';
import Profits from './components/Profits';

const Development = memo(() => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/6')
            .then(res => {
                for (const [key, value] of Object.entries(res.data.object)) {
                    console.log('key', key, JSON.parse(value as string));
                    setData(prev => ({ ...prev, [key]: JSON.parse(value as string) }))
                }
                setLoading(false)
            })
            window.scrollTo(0, 0);
    }, [])
    const whowork = ["Образовательные учреждения: от детских садов до ВУЗов", "Онлайн-курсы", "Образовательные центры", "Каждый клиент независимо от его размера и рода деятельности"];
    return (
        loading ? <LoadingSpinner /> :
       <> <main className='font-helio'>
            <Titles data={(data as any).main[0]} />
            <TargetsComponent data={{
                targets: (data as any).target_development[0],
                whowork:{
                    title:'С кем работаем?',
                    values:(data as any).who_we_work_with},
                advantages: {
                    title: 'Преимущества',
                    values:(data as any).advantages_development 
                }
            }} />
             <Profits data={{
                title: "Что получите?",
                withPhotos: (data as any).profit
            }}/> 
            <Stages data={(data as any).stages}/>

             
        </main>
        <Feedback data={(data as any).form[0]} color='#3269A4'/> 
        </>
    );
});

export default Development;
