import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useNavigate } from "react-router";

export interface NewsRes {
    date: string,
    desc: string,
    title: string,
    images: string,
    id: number,
}

const News = () => {
    // const data = [
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    //     {
    //         title: "Новость",
    //         content: "Комплексный подход к обслуживанию клиентов — возможность получить все необходимые товары, услуги и оптимизация веб-ресурсов в одном месте.",
    //         date: "05.10.2013"
    //     },
    // ];
    const [data, setData] = useState<NewsRes[]>([])
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/3')
            .then(res => {
                for (const [key, value] of Object.entries(res.data.object)) {
                    if (key === 'news')
                        setData(JSON.parse(value as string))
                }
                setLoading(false)
            })
        window.scrollTo(0, 0);
    }, [])


    return loading ? <LoadingSpinner /> : (
        <main>
            <section className="pt-28 font-helio">
                <h1 className="text-orange lg:text-[48px] md:text-[40px] sm:text-3xl text-2xl lg:!leading-[60px] font-bold pb-6 cursor-pointer">Блог</h1>
                <div className='grid grid-cols-1 min-[666px]:grid-cols-2 min-[888px]:grid-cols-3  min-[1300px]:grid-cols-4 gap-4 cursor-pointer'>
                    {data.map((item, index) => {
                        return (
                            // <div
                            //     className='flex flex-col gap-4 rounded-xl border-[1px] border-[#D42E12] p-4'
                            //     key={index}
                            //     onClick={() => { navigate('/news/' + item.id) }}>
                            //     <h3 className='font-bold'>{item.title}</h3>
                            //     <p>{item.desc}</p>
                            //     <span>{item.date}</span>
                            // </div>
                            <div
                                className="relative flex flex-col  lg:md:gap-4 gap-3  items-start  border-[1px] border-orange rounded-2xl lg:p-8 md:p-6 sm:p-4 p-4 font-helio"
                                key={index}
                                onClick={() => { navigate('/news/' + item.id) }}
                            >
                                <span className="lg:text-2xl md:text-xl sm:text-base text-sm font-bold">
                                    {item.title}
                                </span>
                                <span className="lg:text-lg md:text-base sm:text-sm text-sm flex-grow font-helio_c">
                                    {item.desc}
                                </span>
                                <span className="text-[#5A5A5A] lg:text-base md:text-sm sm:text-[12px] text-[12px]">
                                    {item.date}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </section>
        </main>
    );
};

export default News; 