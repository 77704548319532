import { FunctionComponent, useEffect, useState } from "react";
import Info from "./info/Info";
import Programs from "./programs/Programs";
import Company from "./company/Company";
import Adventages from "./adventages/Adventages";
import Questions from "./questions/Questions";
import News from "./news/News";
import Partners from "./partners/Partners";
import axios from "axios";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import Contact from "./contact/Contact";


interface ItProps {

}

const It: FunctionComponent<ItProps> = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/3')
            .then(res => {
                for (const [key, value] of Object.entries(res.data.object)) {
                    console.log('key', key, JSON.parse(value as string));

                    setData(prev => ({ ...prev, [key]: JSON.parse(value as string) }))
                }
                setLoading(false)
            })
        window.scrollTo(0, 0);
    }, [])

    return (
        loading ? <LoadingSpinner /> :
            <main>
                <Info data={(data as any).Slider} />
                <Programs data={(data as any).programs} />
                <Company image={(data as any).about_1t[0].image} text={(data as any).about_1t[0].text} />
                <Adventages data={(data as any).adventages_1t} />
                <Questions data={(data as any).questions} />
                <Partners images={(data as any).partners} />
                <News data={(data as any).news} />
                <Contact data={(data as any).social_1t} />
            </main>
    );
}

export default It;