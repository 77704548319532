import { Fragment, FunctionComponent, useEffect, useState } from "react";
import logo from '../../images/logo.svg'
import BurgerOpen from "../../images/icons/mob-burger-open.svg"
import BurgerClose from "../../images/icons/mob-burger-close.svg"
import { NavLink, useLocation } from "react-router-dom";
import RecordingForm from "../modals/RecordingForm";
import axios from "axios";
import { imageURL } from "../main/Main";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

const Header: FunctionComponent = () => {
    const [mobBurger, setMobBurger] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const togleModal = () => {
        setModalOpen(prev => !prev);
    }
    interface IData {
        Logo: string,
        menu: string
    }


    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<IData[]>()
    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/2')
            .then(res => {
                setData(JSON.parse(res.data.object.info))
                setLoading(false);
            });
    }, []);

    return (
        loading ? <LoadingSpinner /> :
            <>
                <header className={`bg-black flex flex-row justify-center items-center fixed w-full z-30 ${mobBurger ? 'mob_element_close' : 'mob_element_open'}`}>
                    <div className={`${mobBurger ? 'mob_element_close' : 'mob_element_open'} flex flex-row justify-between items-center py-5 w-full mx-5 md:py-3 font-helio text-base text-white max-w-screen-2xl`}>
                        {data?.map((item: any, index: number) => {
                            return <Fragment key={index}>
                                <div>
                                    <NavLink to='/'>
                                        <img src={imageURL + item.Logo} alt="LOGO" />
                                    </NavLink>
                                </div>
                                <ul className="flex flex-row lg:gap-[56px] md:gap-6 sm:gap-3 lg:text-base md:sm:text-sm max-[920px]:hidden">
                                    {
                                        JSON.parse(item.menu).map((el: any, index:number) => {
                                            return <li key={index}>
                                                <NavLink to={el.url} className={(window.location == el.url ? 'text-red underline font-bold' : "")}>
                                                    {el.text}
                                                </NavLink>
                                            </li>
                                        })
                                    }

                                </ul>
                            </Fragment>
                        })}


                        <div className="max-[920px]:hidden">
                            <button className="lg:py-3 lg:px-5 md:py-2 md:px-4 sm:py-2 sm:px-3 bg-[#D42E12] rounded-3xl flex items-center justify-center hover:bg-[#F3573D] transition-all" onClick={() => togleModal()}>
                                <span className="lg:text-lg md:text-base sm:text-sm">
                                    Заказать звонок
                                </span>
                            </button>
                        </div>
                        <div id="burger_open" className="min-[920px]:hidden">
                            <button
                                onClick={() => {
                                    setMobBurger(prev => !prev)
                                }}
                                className="yellow_icon_btn"

                            >
                                <img src={BurgerOpen} alt="Open burger" />
                            </button>
                        </div>
                    </div>
                </header>
                <div id="mob_menu" className={`${mobBurger ? 'mob_element_open' : 'mob_element_close'}`}>
                    <div className="flex flex-col justify-between font-helio h-full">
                        <div className="flex flex-col gap-[50px]">
                            <div className="flex justify-between items-start" onClick={() => {
                                setMobBurger(prev => !prev)
                            }}>
                                <NavLink to='/'  >
                                    <img src={logo} alt="LOGO" />
                                </NavLink>
                                <button

                                    className="pl-4 mt-2"
                                >
                                    <img src={BurgerClose} alt="Close burger" />
                                </button>
                            </div>
                            <ul className="flex flex-col items-start text-white gap-5 text-base">
                                <li onClick={() => {
                                    setMobBurger(prev => !prev)
                                }}>
                                    <NavLink to='/' className={({ isActive }) => (isActive ? 'text-red underline font-bold' : "")}>
                                        Главная
                                    </NavLink>
                                </li>
                                <li onClick={() => {
                                    setMobBurger(prev => !prev)
                                }}>
                                    <NavLink to='/1t' className={({ isActive }) => (isActive ? 'text-red underline font-bold' : "")}>
                                        Обучение 1T
                                    </NavLink>
                                </li>
                                <li onClick={() => {
                                    setMobBurger(prev => !prev)
                                }}>
                                    <NavLink to='/komplecsnoe-snabjenie' className={({ isActive }) => (isActive ? 'text-red underline font-bold' : "")}>
                                        Комплексное снабжение
                                    </NavLink>
                                </li>
                                <li onClick={() => {
                                    setMobBurger(prev => !prev)
                                }}>
                                    <NavLink to='/development' className={({ isActive }) => (isActive ? 'text-red underline font-bold' : "")}>
                                        Развитие
                                    </NavLink>
                                </li>
                            </ul>

                        </div>
                        <div className="">
                            <button className="py-3 px-5 bg-[#D42E12] text-white w-full rounded-3xl flex items-center justify-center" onClick={() => togleModal()}>
                                <span className="text-lg">
                                    Заказать звонок
                                </span>
                            </button>
                        </div>
                    </div>


                    <RecordingForm open={modalOpen} setOpen={() => togleModal()} type="call" />
                </div>
            </>
    );
}

export default Header;