import { FunctionComponent } from "react";
import logo from '../../../images/about/logo.png'
import tw from '../../../images/about/25.png'
import girl from '../../../images/about/girl.png'
import logo2 from '../../../images/about/logo2.png'
import ScrollAnimation from 'react-animate-on-scroll';
import bg from '../../../images/bg-test.png'
import { imageURL } from "../Main";

interface AboutProps {
    text1: string;
    text2: string;
    image1: string;
    image2: string;
    image3: string;
    image4: string;
    image5: string;
}

const About = ({ data }: { data: AboutProps[] }) => {


    return (
        <section className="mb-[160px]">
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-32 md:mb-26 sm:mb-5 mb-5">
                    О компании
                </h1>
            </ScrollAnimation>
            {
                data.map((data, index) => {
                    return <div key={index} className="min-[1400px]:grid min-[1400px]:grid-cols-2 items-center max-[1400px]:flex max-[1400px]:flex-col min-[1400px]:gap-28 max-[1400px]:gap-8">
                        <div className="bg-lightpink flex flex-col rounded-2xl items-center w-[717px] h-[386px] relative max-[1000px]:hidden">
                            <div className="flex flex-row items-center absolute min-[1000px]:-top-36 min-[1000px]:-right-24 max-[1000px]:-top-30 max-[1000px]:-right-0">
                                <img src={imageURL + data.image3} alt="" className="max-[1000px]:max-w-[220px] " />
                                <img src={imageURL + data.image1} alt="" className="max-[1000px]:max-w-[220px] " />
                            </div>
                            <div className="flex flex-row gap-10 items-center absolute -bottom-44 left-14 ">
                                <img src={imageURL + data.image2} alt="" className="max-[1000px]:max-w-[220px] " />
                                <img src={imageURL + data.image4} alt="" className="max-[1000px]:max-w-[220px] " />
                            </div>
                        </div>
                        <div className="min-[1000px]:hidden">
                            <img src={imageURL + data.image5} alt="" />
                        </div>
                        <div className="flex flex-col lg:gap-16 md:gap-14 sm:gap-5 gap-5 font-helio_c text-left">
                            <ScrollAnimation animateIn='fadeIn'>
                                <span className="lg:text-2xl md:text-lg text-left">
                                    {data.text1}
                                </span>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeIn'>
                                <span className="lg:text-2xl md:text-lg text-left">
                                    {data.text2}
                                </span>
                            </ScrollAnimation>
                        </div>
                    </div>
                })
            }

        </section >
    );
}

export default About;