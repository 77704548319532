import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import kod from '../../../images/icons/programs/kod.svg'
import demo from '../../../images/icons/programs/demo.svg'
import bas from '../../../images/icons/programs/bas.svg'
import ii from '../../../images/icons/programs/ii.svg'
import dop from '../../../images/icons/programs/dop.svg'
import simplearrow from '../../../images/icons/simplearrow.svg'
import { imageURL } from "../../main/Main";

interface ItemProgram {
    title: string;
    desc: string;
    icon: string;
    url: string;
    items: string
}

interface ProgramsProps {
    data: ItemProgram[];
}


const Contact: FunctionComponent<ProgramsProps> = (props: ProgramsProps) => {
    const { data } = props
    console.log(data);

    return (
        <section className='flex flex-col font-helio'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-center lg:mb-6 md:mb-5 sm:mb-4 mb-4 text-orange">
                    Мы в социальных сетях
                </h1>
            </ScrollAnimation>
            <h2 className="lg:text-2xl md:text-xl sm:text-lg text-lg text-center max-w-[950px] mx-auto">
                {data.map(item => item.desc)}
            </h2>
            {data[0]?.items &&
                <div className="flex min-[600px]:flex-row max-[600px]:flex-col max-[600px]:items-center gap-4 items-center min-[500px]:mt-10 max-[500px]:mt-3 transition-all mx-auto">
                    {/* @ts-ignore */}
                    {JSON.parse(data[0]?.items).map((item, index: number) => {
                        return <a key={index} href={item.url} ><img src={imageURL + item.icon} alt="" className="opacity-100 hover:opacity-80 transition-all" /></a>
                    })}
                </div>}
        </section>
    );
}

export default Contact;