import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import etp from '../../../images/icons/platforms/etp.svg'
import goz from '../../../images/icons/platforms/goz.png'
import gpb from '../../../images/icons/platforms/gpb.svg'
import gz from '../../../images/icons/platforms/gz.png'
import roseltorg from '../../../images/icons/platforms/roseltorg.svg'
import russia from '../../../images/icons/platforms/russia.png'
import sber from '../../../images/icons/platforms/sber.svg'
import tek from '../../../images/icons/platforms/tek.png'
import tender from '../../../images/icons/platforms/tender.png'
import zakaz from '../../../images/icons/platforms/zakaz.png'
import { imageURL } from "../../main/Main";


interface PlatformsProps {
    text: string,
    url: string,
    image: string
}




const Platforms = ({data}:{data:PlatformsProps[]}) => {

    


    return (
        <section className="relative overflow-hidden max-[900px]:p-0 ">
            <div className="p-3">

                <ScrollAnimation animateIn='fadeInUp'
                    >
                    <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                        На каких площадках мы работаем
                    </h1>
                </ScrollAnimation>
                <div className="z-2">
                    <Swiper
                        scrollbar={{
                            hide: false,
                            draggable: true,

                        }}
                        slidesPerView={'auto'}
                        spaceBetween={30}
                        modules={[Scrollbar]}

                        className="z-2 pink-scroll"
                    // breakpoints={{
                    //     1440: {
                    //         slidesPerView: 4,
                    //     },
                    //     1088: {
                    //         slidesPerView: 3,
                    //     },
                    //     768: {
                    //         slidesPerView: 2,
                    //     },
                    //     0:{
                    //         slidesPerView: 1
                    //     }
                    // }}
                    >
                        {
                            data.map((item, index) => {
                                return <SwiperSlide key={index} className="max-w-[501px]">
                                    <div className="flex flex-col justify-around items-center gap-4 p-4 border-[1px] h-[220px] mb-14 border-pink bg-white rounded-2xl max-w-[501px] group  hover:bg-pink transition-all ">
                                        <img src={ imageURL + item.image} alt="" />
                                        {/* <span className="text-2xl text-left font-bold text-pink font-helio group-hover:text-white w-4/5">
                                            {item.image}
                                        </span> */}
                                        <div className="flex flex-col items-center">
                                            <span className="text-lg font-[300]  text-center text-black font-helio_c group-hover:text-white">
                                                {item.text}
                                            </span>
                                            <span className="text-lg font-[300] text-left text-black font-helio_c group-hover:text-white">
                                                {item.url}
                                            </span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>

                </div>
            </div>
        </section>
    );
}

export default Platforms;