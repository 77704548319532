import { FunctionComponent, useEffect, useState } from "react";
import logo from '../../images/logo.svg'
import TG from '../../images/icons/tg.svg'
import VK from '../../images/icons/vk.svg'
import Phone from '../../images/icons/phone.svg'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import axios from "axios";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import { imageURL } from "../main/Main";

interface FooterProps {

}

const Footer: FunctionComponent<FooterProps> = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    useEffect(() => {
        axios.get('https://admin.aversprofi.ru/rest/items/7')
            .then(res => {
                setData(res.data.object)
                setLoading(false);
            });
    }, []);


    return (
        <footer className="bg-black flex flex-col justify-center items-center pb-4 px-5 font-helio">
            {loading ? <LoadingSpinner /> :
                <>
                    <div className="flex flex-row justify-between items-center pt-14 w-full  text-base text-gray max-w-screen-2xl ">
                        <div className="flex flex-col items-start gap-6">
                            <div className="w-[237px] max-[500px]:w-[161px]">
                                <img src={logo} alt="LOGO" className="w-full" />
                            </div>
                            <YMaps>
                                <Map style={{ 'width': '90vw', height: '25vh' }} className="min-[920px]:hidden" defaultState={{ center: [56.030442, 92.828479], zoom: 9 }}>
                                    <Placemark geometry={[56.030442, 92.828479]} />
                                </Map>
                            </YMaps>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 lg:gap-10 md:gap-8 sm:gap-6 gap-6">
                                {/* @ts-ignore */}
                                {JSON.parse(data.footer).map((item, index: number) => {
                                    return <div key={index} className="flex flex-col lg:gap-4 md:gap-3 sm:gap-2 gap-2">
                                        <a href={item.url} className="text-[#737373] font-bold text-lg">
                                            {item.page}
                                        </a>
                                        <div className="flex flex-col lg:gap-2 md:gap-2 sm:gap-2 gap-2">
                                            {
                                                item.items != '' && JSON.parse(item.items).map((el: any, index: number) => {
                                                    return <a key={index} href={el.url} className={`lg:text-sm md:text-base sm:text-base ${el.type == 'bold' && 'font-bold'}`}>
                                                        {el.text}
                                                    </a>
                                                })
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                            <div className="flex flex-col items-start max-w-[60%] text-sm text-left">
                                {/* @ts-ignore */}
                                {JSON.parse(data.footer_info).map((item, index: number) => {
                                    return <span key={index}>
                                        {item.text}
                                    </span>
                                })}

                            </div>
                            <div className="flex flex-row gap-4 items-center min-[500px]:mt-10 max-[500px]:mt-3] transition-all">
                                {/* @ts-ignore */}
                                {JSON.parse(data?.social).map((item, index: number) => {
                                    return <a key={index} href={item.url} ><img src={imageURL + item.icon} alt="" className="max-[500px]:w-[40px] opacity-80 hover:opacity-100 transition-all" /></a>
                                })}
                            </div>
                        </div>
                        <YMaps>
                            <Map className="min-[1250px]:w-[764px] min-[1250px]:h-[400px] md:w-[500px] md:h-[257px] max-[920px]:hidden " defaultState={{ center: [56.030442, 92.828479], zoom: 9 }}>
                                <Placemark geometry={[56.030442, 92.828479]} />
                            </Map>
                        </YMaps>
                    </div>
                    {/* @ts-ignore */}
                    <a href={imageURL + data.politics} className="text-base text-gray max-[920px]:mt-5">
                        Политика конфиденциальности
                    </a>
                </>
            }



        </footer>
    );
}

export default Footer;