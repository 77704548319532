import { FunctionComponent } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import ScrollAnimation from 'react-animate-on-scroll';
import customBG from '../../../images/background.png'


interface AdvantagesProps {
    title: string,
    desc: string
}



const Advantages = ({ data }: { data: AdvantagesProps[] }) => {
    return (
        <section className="relative overflow-hidden max-[900px]:p-0 ">
            <div className="p-3">

                <ScrollAnimation animateIn='fadeInUp'
                >
                    <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                        Преимущества
                    </h1>
                </ScrollAnimation>
                <div className="z-2">
                    <Swiper
                        scrollbar={{
                            hide: false,
                            draggable: true,

                        }}
                        slidesPerView={'auto'}
                        spaceBetween={30}
                        modules={[Scrollbar]}

                        className="z-2 pink-scroll"
                    // breakpoints={{
                    //     1440: {
                    //         slidesPerView: 4,
                    //     },
                    //     1088: {
                    //         slidesPerView: 3,
                    //     },
                    //     768: {
                    //         slidesPerView: 2,
                    //     },
                    //     0:{
                    //         slidesPerView: 1
                    //     }
                    // }}
                    >
                        {
                            data.map((item, index) => {
                                return <SwiperSlide key={index} className="max-w-[501px]">
                                    <div className="flex flex-col items-start lg:gap-4 md:gap-3 sm:gap-2 gap-2 max-[400px]:min-h-[200px] lg:p-4 md:p-3 sm:p-3 p-3 border-[1px] min-h-[220px] mb-14 border-pink bg-white rounded-2xl max-w-[501px] group  hover:bg-pink transition-all ">
                                        <span className="lg:text-2xl md:text-xl sm:text-lg text-lg text-left font-bold text-pink font-helio group-hover:text-white w-4/5">
                                            {item.title}
                                        </span>
                                        <span className="lg:text-lg md:text-base sm:text-sm text-sm font-[300] text-left text-black font-helio_c group-hover:text-white">
                                            {item.desc}
                                        </span>
                                    </div>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>

                </div>
            </div>
        </section>
    );
}

export default Advantages;