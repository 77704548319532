import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import transImage from '../../../images/1t/partners/20.35.png'
import kod from '../../../images/1t/partners/kod.png'
import fin from '../../../images/1t/partners/fin.png'
import sf from '../../../images/1t/partners/sf.png'
import obr from '../../../images/1t/partners/obr.png'
import unit from '../../../images/1t/partners/unit.png'
import { imageURL } from "../../main/Main";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";


interface itemDelivery {
    MIGX_id: number;
    image: string
}

interface DeliveryProps {
    images: itemDelivery[];
}

// const images = [transImage, kod, fin, sf, obr, unit]

const Partners: FunctionComponent<DeliveryProps> = (props: DeliveryProps) => {
    const { images } = props;

    return (
        <section className='flex flex-col lg:gap-13 md:gap-13 sm:gap-8 gap-8 font-helio'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                    Партнеры
                </h1>
            </ScrollAnimation>
            <Swiper
                scrollbar={{
                    hide: false,
                    draggable: true,
                }}
                slidesPerView={'auto'}
                spaceBetween={15}
                className='small-scroll'
                modules={[Scrollbar]}
            >
                {
                    images.map(item => {
                        return <SwiperSlide key={item.MIGX_id} className=" max-w-[309px]">
                            {/* @ts-ignore */}
                            <div className="lg:p-6 md:p-3 sm:p-2 p-2 max-w-[239px]">
                                <img key={item.MIGX_id} src={imageURL + item.image} alt="" className="w-full h-full" />
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    );
}

export default Partners;