import { FunctionComponent } from "react";
import image from '../../../images/works.png';
import Case from '../../../images/icons/Case.svg';
import Cap from '../../../images/icons/cap.svg';
import Shield from '../../../images/icons/Shield.svg';
import Health from '../../../images/icons/Health.svg';
import Shock from '../../../images/icons/shock.svg';
import ScrollAnimation from "react-animate-on-scroll";
import { imageURL } from "../Main";


interface WorksProps {
    title: string;
    desc: string;
    image: string;
    items: string;
}

const Works = ({ data }: { data: WorksProps[] }) => {
    return (
        <section>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                    С кем работаем
                </h1>
            </ScrollAnimation>
            {
                data.map((item, index) => {
                    return <div key={index} className="flex flex-col lg:gap-14 md:gap-10 sm:gap-6 gap-6  text-left">
                        <ScrollAnimation animateIn='fadeIn'>
                            <span className="lg:text-2xl md:text-xl sm:text-sm text-sm font-helio_c text-left ">
                                {item.desc}
                            </span>
                        </ScrollAnimation>
                        <img src={imageURL + item.image} alt="" className="max-[1000px]:hidden" />

                        <div className="flex flex-row gap-2 flex-wrap justify-around">
                            {JSON.parse(item.items).map((el:any, index:number) => {
                                return <div key={index} className="bg-black lg:p-4 md:p-3 sm:p-2 p-2 rounded-2xl flex flex-row items-center justify-center text-white gap-3 lg:text-[21px] md:text-lg sm:text-base text-base font-helio_c lg:md:max-w-[300px] w-full">
                                    <img src={imageURL + el.icon} alt="" />
                                    <span className="text-left w-full leading-6 font-[300]">
                                        {el.text}
                                    </span>
                                </div>
                            })}

                        </div>
                    </div>
                })
            }


        </section>
    );
}

export default Works;