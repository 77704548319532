import { FunctionComponent } from "react";
import { useSwiper } from "swiper/react";
import rightarrow from '../../../images/icons/rightarrow.svg'


interface NextButtonProps {
    background: string
}

const NextButton: FunctionComponent<NextButtonProps> = ({ background }) => {
    const swiper = useSwiper()
    return (
        <div className={`min-w-[50px] h-[50px] flex items-center justify-center rounded-full hover:opacity-70 cursor-pointer bg-[${background}]`} style={{backgroundColor: background}} onClick={() => swiper.slideNext()}>
            <img src={rightarrow} alt="" className="w-1/2 h-1/2" />
        </div>);
}

export default NextButton;