import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Directions from './components/main/directions/Directions';
import About from './components/main/about/About';
import Works from './components/main/works/Works';
import Advantages from './components/main/advantages/Advantages';
import Info from './components/main/info/Info';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/main/Main';
import Supply from './components/Supply/Supply';
import It from './components/it/It';
import ProgramTemplate from './components/programs/ProgramTemplate';
import Development from './components/development/Development';
import NotFoundPage from './components/utils/NotFoundPage';
import WrapperContainer from './components/utils/WrapperContainer';
import News from './components/news/News';
import ItemNews from './components/news/ItemNews/ItemNews';



function App() {
  return (
    <>
      <div id='app'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<WrapperContainer><Main /></WrapperContainer>} />
            <Route path="/komplecsnoe-snabjenie" element={<WrapperContainer><Supply /></WrapperContainer>} />
            <Route path="/1t" element={<WrapperContainer><It /></WrapperContainer>} />
            <Route path='/programs' >
              <Route path=':program' element={<WrapperContainer><ProgramTemplate /></WrapperContainer>} />
            </Route>
            <Route path='/development' element={<WrapperContainer><Development /></WrapperContainer>} />
            <Route path='/news' element={<WrapperContainer><News /></WrapperContainer>} />
            <Route path='/news' >
              <Route path=':id' element={<WrapperContainer><ItemNews /></WrapperContainer>} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />

            {/* <Route path="two" element={<PageTwo />} /> */}
          </Routes>
        </BrowserRouter>

      </div>

    </>
  );
}

export default App;
