import Header from "../header/Header";
import Footer from "../footer/Footer";
import {ReactElement} from "react";


interface WrapperContainerProps{
    children: ReactElement
}

export const WrapperContainer = ({children}:WrapperContainerProps) => {
    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
};

export default WrapperContainer;