import { FunctionComponent } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { imageURL } from "../../main/Main";

interface TrajectoriesProps {
    data: {
        title: string,
        target: string,
        whocan: {
            title: string,
            values: {
                text:string
            }[]
        },
        approaches: {
            title: string,
            values: {
                text: string,
                icon: string
            }[]
        }
    }
}

const Trajectories: FunctionComponent<TrajectoriesProps> = ({ data }) => {    
    return (
        <section>
            <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                {data.title}
            </h1>
            {/* <div className="flex flex-col overflow-hidden lg:gap-[60px] md:gap-[40px] sm:gap-[30px] gap-6"> */}
            <div className="flex flex-col gap-6 lg:mb-[60px] md:mb-[40px] sm:mb-6 mb-6">
                <h2 className="lg:text-[28px] md:text-2xl sm:text-xl text-lg font-bold">
                    Цель —
                </h2>
                <span className="min-[1000px]:w-1/2 font-helio_c lg:text-2xl md:text-xl sm:text-base text-sm">
                    {data.target}
                </span>
            </div>
            <div className="flex flex-col lg:gap-10 md:gap-8 sm:gap-6 gap-6 lg:mb-[60px] md:mb-[40px] sm:mb-6 mb-6">
                <h2 className="lg:text-[28px] md:text-2xl sm:text-xl text-lg font-bold">
                    {data.whocan.title}
                </h2>
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 lg:gap-6 md:gap-4 sm:gap-3 gap-[10px]">
                    {
                        data.whocan.values.map((item, index:number) => {
                            return <div key={index} className="bg-orange text-white py-6 px-3 text-center lg:text-2xl md:text-xl sm:text-base text-[12px] rounded-[20px] flex justify-center items-center">
                                {item.text}
                            </div>
                        })
                    }

                </div>
            </div>
            <div className="flex flex-col lg:gap-10 md:gap-8 sm:gap-6 gap-6 lg:mb-[40px] md:mb-[30px] sm:mb-6 mb-6">
                <h2 className="lg:text-[28px] md:text-2xl sm:text-xl text-lg font-bold">
                    {data.approaches.title}
                </h2>
            </div>
            <Swiper
                scrollbar={{
                    hide: false,
                    draggable: true,
                }}
                slidesPerView={'auto'}
                autoHeight={true}
                spaceBetween={15}
                modules={[Scrollbar]}
                className="items-stretch flex-grow orange-swiper overflow-hidden"
            >
                {
                    data.approaches.values.map((item, index) => {
                        return <SwiperSlide key={index} className="max-w-[502px] pb-10 flex-grow items-stretch">
                            <div className="flex flex-col lg:gap-6 md:gap-4 lg:min-h-[234px] md:min-h-[204px] sm:min-h-[164px] min-h-[124px]  sm:gap-3 gap-3 border-orange border-[1px] lg:py-[30px] md:py-6 sm:py-4 py-4 lg:px-[30px] md:px-6 sm:px-4 px-4 lg:text-2xl md:text-xl sm:text-base text-sm rounded-[20px]">
                                <div className="lg:w-[40px] md:w-[35px] sm:w-[30px] w-[30px] lg:h-[40px] md:h-[35px] sm:h-[30px] h-[30px]">
                                    <img src={imageURL+item.icon} alt="" className="w-full h-full" />
                                </div>
                                <span>
                                    {item.text}
                                </span>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
            {/* </div> */}
        </section>
    );
}

export default Trajectories;