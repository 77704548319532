import { FunctionComponent } from "react";
import d1 from '../../../images/d1.png'
import d2 from '../../../images/d2.png'
import d3 from '../../../images/d3.png'
import { IDirections } from "../types";
import { imageURL } from "../Main";
import { Link } from "react-router-dom";

interface DirectionsProps {

}

const Directions = ({ data }: { data: IDirections[] }) => {
    return (
        <section>
            <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                Направления
            </h1>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-[24px]">
                {data.map((item, index) => {
                    return <Link to={item.buttonUrl} key={index} className={`flex flex-col gap-4 max-[650px]:gap-2 text-white text-2xl items-start p-7 max-[650px]:p-3 max-w-[502px] rounded-2xl group transition-all font-helio`} style={{ backgroundColor: item.colorCard }}>
                        <div className="w-full rounded-2xl bg-white h-[345px] flex items-center justify-center overflow-hidden">
                            <img src={imageURL + item.image} alt="" className="group-hover:scale-105 transition-all object-contain" />
                        </div>
                        <span className="font-bold font-helio lg:text-2xl md:text-xl sm:text-xl text-xl">
                            {item.title}
                        </span>
                        <span className="text-left font-[300] font-helio_c lg:text-2xl md:text-lg max-[650px]:text-sm">
                            {item.text}
                        </span>
                        <span className="underline flex items-center justify-center after:content-[url(./images/icons/arrow.svg)] after:ml-3 after:flex after:justify-center after:items-center text-lg">Перейти</span>
                    </Link>
                })}

            </div>
        </section>
    );
}

export default Directions;