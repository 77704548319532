import { FunctionComponent } from "react";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { imageURL } from "../../main/Main";

interface AdventagesProps {
    data: {
        title: string,
        values: {
            title: string,
            desc: string,
            image: string
        }[]
    }
}

const Adventages: FunctionComponent<AdventagesProps> = ({ data }) => {
    return (
        <section>
            <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left lg:mb-10 md:mb-8 sm:mb-6 mb-6">
                {data.title}
            </h1>
            <Swiper
                scrollbar={{
                    hide: false,
                    draggable: true,
                }}
                slidesPerView={'auto'}
                autoHeight={true}
                spaceBetween={15}
                modules={[Scrollbar]}
                className="items-stretch orange-swiper flex-grow"
            >
                {
                    data.values.map((item, index) => {
                        return <SwiperSlide key={index} className="max-w-[370px] pb-10 flex-grow items-stretch">
                            <div className="relative min-[1000px]:min-h-[523px] flex flex-col lg:md:gap-[22px] sm:gap-4 gap-3  items-start max-w-[501px] border-[1px] border-orange rounded-2xl lg:p-6 md:p-5 sm:p-4 p-4 font-helio">
                                <span className="lg:text-2xl md:text-xl sm:text-lg text-lg font-bold text-orange">
                                    {'0' + (index + 1)}
                                </span>
                                <div className="max-h-[380px]">
                                    <img src={imageURL+item.image} alt="" className="max-h-[180px]" />
                                </div>
                                <span className="lg:text-[22px] md:text-xl sm:text-base text-base font-bold">
                                    {item.title}
                                </span>
                                <span className="lg:text-lg md:text-base sm:text-sm text-sm flex-grow font-helio_c">
                                    {item.desc}
                                </span>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    );
}

export default Adventages;