import { FunctionComponent } from "react";
import nal from '../../../images/icons/payment/nal.svg'
import yr from '../../../images/icons/payment/yr.svg'
import pred from '../../../images/icons/payment/pred.svg'
import later from '../../../images/icons/payment/later.svg'
import ScrollAnimation from "react-animate-on-scroll";
import { imageURL } from "../../main/Main";



interface DeliveryProps {
    text: string,
    icon: string
}


const Payment = ({ data }: { data: DeliveryProps[] }) => {
    return (
        <section className='flex flex-col lg:gap-13 md:gap-13 sm:gap-8 gap-8 font-helio'>
            <ScrollAnimation animateIn='fadeInUp'
            >
                <h1 className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[24px] font-bold font-helio text-left ">
                    Способы оплаты
                </h1>
            </ScrollAnimation>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4">
                {
                    data.map((item, index:number) => {
                        return <div key={index} className="lg:p-7 md:p-5 sm:p-4 p-3 border-pink border-[1px] flex flex-col gap-3 items-start rounded-xl">
                            <img src={imageURL + item.icon} alt="" className="max-w-[42px] h-[41px] max-[500px]:max-w-[20px] max-[500px]:h-[20px] " />
                            <span className="lg:text-2xl md:text-xl sm:text-base text-sm text-black">
                                {item.text}
                            </span>
                        </div>
                    })
                }
            </div>

        </section>
    );
}

export default Payment;